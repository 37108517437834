import { combineReducers } from "redux";
import authReducer from "./Auth/reducer";
import userReducer from "./User/reducer";
import productReducer from "./Hot_Products/reducer";
import supplierReducer from "./Supplier/reducer";
import invoiceReducer from "./Bill-Invoice/reducer";

export default combineReducers({
  Auth: authReducer,
  User: userReducer,
  Product: productReducer,
  Supplier: supplierReducer,
  Invoice: invoiceReducer,
});
