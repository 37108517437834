import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import ReactDOM from 'react-dom';

import './index.css';
import App from './App';

import 'bootstrap/dist/css/bootstrap.min.css';
import "./assets/scss/global.scss"
import "./assets/scss/components/Main/index.scss"
import "./assets/scss/components/Button/index.scss"
import "./assets/scss/components/Form/index.scss"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";




ReactDOM.render(
    <BrowserRouter>
        <App />
    </BrowserRouter>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.register();
