import React, { useState } from "react";
import { Form, Row, Col } from "reactstrap";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import ReactCardFlip from "react-card-flip";

import logo from "../../assets/images/RSMK_LOGO_1.svg";
import bannerGif from "../../assets/images/bannerGif.gif";
import Icon from "react-icons-kit";
import { ic_email } from "react-icons-kit/md/ic_email";
import { ic_lock } from "react-icons-kit/md/ic_lock";
import { ic_remove_red_eye } from "react-icons-kit/md/ic_remove_red_eye";
import { eyeBlocked } from "react-icons-kit/icomoon/eyeBlocked";

import CustomButton from "../../components/CustomButton";
import InputText from "../../components/InputText";

import { loginUser } from "../../redux/Auth/actions";

import "../../assets/scss/components/Login/index.scss";

function Login() {
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(true);
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");

  const handleUserNameChange = (e) => {
    setUserName(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleToggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleToast = (message) => {
    toast.error(message, {
      position: "bottom-center",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      text: "center",
    });
  };

  const handleLogin = (e) => {
    e.preventDefault();
    if (userName !== "" && password !== "") {
      dispatch(loginUser({ userName, password }));
    } else {
      handleToast("Invalid Username & password");
    }
  };

  return (
    <div className="login-wrapper">
      <div className="whiteBg"></div>
      <div className="image-container">
        <img className="responsive-image" src={bannerGif} alt="Logo" />{" "}
      </div>
      <div className="main-bg d-flex flex-wrap align-items-center">
        <ReactCardFlip flipDirection="horizontal">
          <div>
            <div className="login-container">
              <div className="login-logo mx-auto">
                <img src={logo} alt="Logo" />
              </div>
              <div className="app-auth-container m-auto position-relative">
                <Form onSubmit={handleLogin}>
                  <Row>
                    <Col sm={12} className="position-relative">
                      <InputText
                        inputType={"text"}
                        inputName={"userName"}
                        value={userName}
                        inputPlaceholder={"Enter User Name"}
                        tooltip={"Use Alphanumeric Only"}
                        customOnChange={handleUserNameChange}
                      />

                      <Icon
                        size={22}
                        className="inputIcon LeftIcon"
                        icon={ic_email}
                      />
                    </Col>
                    <Col sm={12} className="position-relative">
                      <InputText
                        inputType={showPassword ? "password" : "text"}
                        inputName={"password"}
                        value={password}
                        inputPlaceholder={"Enter Password"}
                        tooltip={"Use Alphanumeric Only"}
                        customOnChange={handlePasswordChange}
                      />
                      <Icon
                        size={22}
                        className="inputIcon LeftIcon"
                        icon={ic_lock}
                      />
                      <Icon
                        size={18}
                        className="RightIcon inputIcon"
                        onClick={handleToggleShowPassword}
                        icon={showPassword ? eyeBlocked : ic_remove_red_eye}
                      />
                    </Col>
                  </Row>
                  <div className="bottom-sec">
                    <div className="d-flex align-items-center justify-content-center position-relative flex-column">
                      <CustomButton
                        customType={"submit"}
                        name={"Login"}
                        customClass={
                          "border-0 rotate-0 bgSecondary customButtonsmall rounded-pill mb-4 btn-width-sm"
                        }
                      />
                      {/* <a
                        className="text-center mx-2"
                        onClick={handleCardFlipped}
                      >
                        Forget Password?
                      </a> */}
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          </div>
          <div>
            <div className="login-container">
              <div className="login-logo mx-auto">
                <img src="" alt="Logo" />
              </div>
              <div
                className="app-auth-container m-auto position-relative"
                // hidden={this.state.hiddenForgetpassword}
              >
                <Form>
                  <Row>
                    {/* <Col sm={12} className="position-relative">
                      <h3 className="textSecondary">Forgot Password</h3>
                    </Col> */}

                    <Col lg={12}>
                      <InputText
                        inputType={"text"}
                        inputName={"email"}
                        inputPlaceholder={"Enter Username"}
                        // customOnChange={handleForgetPasswordEmail}
                      />
                      <Icon
                        size={22}
                        className="inputIcon LeftIcon"
                        icon={ic_email}
                      />
                    </Col>
                  </Row>
                  <div className="bottom-sec">
                    <div className="d-flex align-items-center flex-column justify-content-center position-relative">
                      <CustomButton
                        customType={"submit"}
                        name={"Reset Password"}
                        customClass={
                          "border-0 bgSecondary customButtonsmall rounded-pill mb-4 btn-width-sm"
                        }
                      />
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </ReactCardFlip>
      </div>
    </div>
  );
}

export default Login;
