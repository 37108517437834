import React, { useEffect, useState } from "react";
import { Row, Col, Form, FormGroup } from "reactstrap";
import { useDispatch, useSelector, shallowEqual } from "react-redux";

import InputText from "../../components/InputText";
import CartTitle from "../../components/CartTitle";
import CustomButton from "../../components/CustomButton";

import history from "../../history";
import { getAllProducts } from "../../redux/Hot_Products/actions";
import { downloadPdfInvoice } from "../../redux/Bill-Invoice/actions";

const staticDeductions = [
  "Discount",
  "Add amount",
  "Less amount",
  "GST",
  "Round off",
];

export default function InvoicesDetails() {
  const dispatch = useDispatch();
  const [id, setId] = useState("");
  const [gst, setGst] = useState("");
  const [transport, setTransport] = useState("");
  const [narration, setNarration] = useState("");
  const [billNumber, setBillNumber] = useState("");
  const [caseNumber, setCaseNumber] = useState("");
  const [totalAmountAfterDeduction, setTotalAmountAfterDeduction] =
    useState("");
  const [
    totalAmountAfterDeductionInWords,
    setTotalAmountAfterDeductionInWords,
  ] = useState("");
  const [
        roundOff,
        setRoundOff,
      ] = useState("");

  const [product, setProduct] = useState([
    {
      id: null,
      invoiceId: null,
      productId: null,
      quantity: 1,
      price: null,
      total: null,
      productName: "",
    },
  ]);
  const [deductions, setDeductions] = useState([
    {
      adjustmentId: null,
      invoiceId: null,
      adjustmentType: "",
      percentage: null,
      amount: null,
      narration: "",
      finalamountAfterCalcultion: 0,
    },
  ]);

  useEffect(() => {
    dispatch(getAllProducts());
    const invoicesData = sessionStorage.getItem("invoices");
    const parsedInvoice = JSON.parse(invoicesData);
    handleUpdateInvoice(parsedInvoice);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { allProduct } = useSelector((state) => state.Product, shallowEqual);

  const handleDownloadInvoicePdf = () => {
    dispatch(downloadPdfInvoice(id));
  };

  const handleUpdateInvoice = (invoicesData) => {
    let datatoMap = invoicesData.productList.map((item) => ({
      id: item.id,
      invoiceId: item.invoiceId,
      productId: item.productId,
      quantity: item.quantity,
      price: item.price,
      total: item.price * item.quantity,
      productName: item.productName,
    }));

    setProduct(datatoMap);
    setId(invoicesData.invoiceId);
    setGst(invoicesData.gst);
    setNarration(invoicesData.narration);
    setBillNumber(invoicesData.billNumber);
    setCaseNumber(invoicesData.caseNumber);
    setTransport(invoicesData.transport);
    setDeductions(invoicesData.invoiceAdjustmentDTO);
    setTotalAmountAfterDeduction(invoicesData.invoiceTotalDTO.totalAmount);
    setTotalAmountAfterDeductionInWords(
      invoicesData.invoiceTotalDTO.totalInWords
    );
    setRoundOff(invoicesData.invoiceTotalDTO.roundOff)
  };

//   console.log({product})

 const handleGoBack = () => {
    history.goBack();
  };

  return (
    <div>
      <Form>
        <Row>
          <Col sm={12}>
            <CartTitle title={"Invoice"} customBtnHide={true}/>
          </Col>
        </Row>
        <div className=" backgroundCard CardWithLightBg rounded">
          <Row className=" px-2 px-lg-4 py-4">
            <Col lg={4} className={`${gst !== "" ? "valueActive" : ""}  mb-3`}>
              <InputText
                labelName={"GST"}
                inputType={"text"}
                inputName={"gst"}
                tooltip={""}
                customValue={gst}
              />
            </Col>
            <Col
              lg={4}
              className={`${transport !== "" ? "valueActive" : ""}  mb-3`}
            >
              <InputText
                labelName={"Transport"}
                inputType={"text"}
                inputName={"transport"}
                tooltip={""}
                customValue={transport}
              />
            </Col>
            <Col
              lg={4}
              className={`${narration !== "" ? "valueActive" : ""}  mb-3`}
            >
              <InputText
                labelName={"Narration"}
                inputType={"text"}
                inputName={"narration"}
                tooltip={""}
                customValue={narration}
              />
            </Col>
            <Col
              lg={4}
              className={`${billNumber !== "" ? "valueActive" : ""}  mb-3`}
            >
              <InputText
                labelName={"Invoice Number"}
                inputType={"text"}
                inputName={"billNumber"}
                tooltip={""}
                customValue={billNumber}
              />
            </Col>
            <Col
              lg={4}
              className={`${caseNumber !== "" ? "valueActive" : ""}  mb-3`}
            >
              <InputText
                labelName={"Case Number"}
                inputType={"text"}
                inputName={"caseNumber"}
                tooltip={""}
                customValue={caseNumber}
              />
            </Col>
          </Row>
        </div>

        <Row>
          <Col sm={12}>
            <CartTitle title={"Products"} customBtnHide={true} />
          </Col>
        </Row>
        <div className=" backgroundCard CardWithLightBg rounded">
          {product.map((item, index) => (
            <Row className=" px-2 px-lg-4 py-4">
              <Col lg={2} className={`valueActive mb-3`}>
                <InputText
                  labelName={"S No"}
                  inputType={"text"}
                  inputName={"quantity"}
                  tooltip={""}
                  customValue={index + 1}
                />
              </Col>
              <Col
                lg={2}
                className={`${
                  item.productId !== null ? "valueActive" : ""
                }  mb-3`}
              >
                <FormGroup>
                  <select
                    className="form-control"
                    name="productId"
                    value={item.productName}
                  >
                    <option>{item.productName}</option>
                    {allProduct?.map((item, index) => (
                      <option value={JSON.stringify(item)}>{item.name}</option>
                    ))}
                  </select>
                  <span className="highlight" />
                  <span className="bar" />
                </FormGroup>
              </Col>
              <Col
                lg={2}
                className={`${
                  item.quantity !== null ? "valueActive" : ""
                }  mb-3`}
              >
                <InputText
                  labelName={"Quantity"}
                  inputType={"text"}
                  inputName={"quantity"}
                  tooltip={""}
                  customValue={item.quantity}
                />
              </Col>
              <Col
                lg={2}
                className={`${item.price !== null ? "valueActive" : ""}  mb-3`}
              >
                <InputText
                  labelName={"Price (RS)"}
                  inputType={"text"}
                  inputName={"price"}
                  tooltip={""}
                  customValue={item.price}
                />
              </Col>
              <Col
                lg={2}
                className={`${item.total !== null ? "valueActive" : ""}  mb-3`}
              >
                <InputText
                  labelName={"Amount"}
                  inputType={"text"}
                  inputName={"total"}
                  tooltip={""}
                  customValue={item.total}
                />
              </Col>
            </Row>
          ))}
        </div>

        <Row>
          <Col sm={12}>
            <CartTitle title={"Adjustments"} customBtnHide={true} />
          </Col>
        </Row>
        <div className=" backgroundCard CardWithLightBg rounded">
          {deductions.map((item, index) => (
            <Row className=" px-2 px-lg-4 py-4">
              <Col lg={2} className={`valueActive mb-3`}>
                <InputText
                  labelName={"S No"}
                  inputType={"text"}
                  inputName={"quantity"}
                  tooltip={""}
                  customValue={index + 1}
                />
              </Col>
              <Col
                lg={2}
                className={`${
                  item.adjustmentType !== null ? "valueActive" : ""
                }  mb-3`}
              >
                <FormGroup>
                  <select
                    className="form-control"
                    name="adjustmentType"
                    value={item.adjustmentType}
                  >
                    <option>Select Adjustments</option>
                    {staticDeductions?.map((item, index) => (
                      <option value={item}>{item}</option>
                    ))}
                  </select>
                  <span className="highlight" />
                  <span className="bar" />
                </FormGroup>
              </Col>
              <Col
                lg={2}
                className={`${
                  item.narration !== "" ? "valueActive" : ""
                }  mb-3`}
              >
                <InputText
                  labelName={"Narration"}
                  inputType={"text"}
                  inputName={"narration"}
                  tooltip={""}
                  customValue={item.narration}
                />
              </Col>
              <Col
                lg={2}
                className={`${
                  item.percentage !== null ? "valueActive" : ""
                }  mb-3`}
              >
                <InputText
                  labelName={"Percentage %"}
                  inputType={"text"}
                  inputName={"percentage"}
                  tooltip={""}
                  customValue={item.percentage}
                />
              </Col>
              <Col
                lg={2}
                className={`${item.amount !== null ? "valueActive" : ""}  mb-3`}
              >
                <InputText
                  labelName={"Amount"}
                  inputType={"text"}
                  inputName={"amount"}
                  tooltip={""}
                  customValue={item.amount}
                />
              </Col>
            </Row>
          ))}
        </div>

        <Row>
          <Col sm={12}>
            <CartTitle title={"Summery"} customBtnHide={true} />
          </Col>
        </Row>
        <div className=" backgroundCard CardWithLightBg rounded">
          <Row className=" px-2 px-lg-4 py-4">
            <Col
              lg={4}
              className={`${
                roundOff !== "" ? "valueActive" : ""
              }  mb-3`}
            >
              <InputText
                labelName={"Round Off Amount"}
                inputType={"text"}
                inputName={"roundOff"}
                tooltip={""}
                customValue={roundOff}
              />
            </Col>
            <Col
              lg={4}
              className={`${
                totalAmountAfterDeduction !== "" ? "valueActive" : ""
              }  mb-3`}
            >
              <InputText
                labelName={"Final Amount"}
                inputType={"text"}
                inputName={"totalAmountAfterDeduction"}
                tooltip={""}
                customValue={totalAmountAfterDeduction}
              />
            </Col>{" "}
            <Col
              lg={4}
              className={`${
                totalAmountAfterDeductionInWords !== "" ? "valueActive" : ""
              }  mb-3`}
            >
              <InputText
                labelName={"Final Amount In Words"}
                inputType={"text"}
                inputName={"totalAmountAfterDeductionInWords"}
                tooltip={""}
                customValue={totalAmountAfterDeductionInWords}
              />
            </Col>{" "}
            <Col sm="12">
              <div className="d-flex justify-content-end mb-sm-0 mb-lg-4">
                <CustomButton
                  customType={"button"}
                  name={"Cancel"}
                  customClass={"customButtonsmall bgPrimary btn-width-sm"}
                  customClick={handleGoBack}
                />{" "}
                <CustomButton
                  customType={"button"}
                  name={"Download Invoice"}
                  customClass={"customButtonsmall bgPrimary btn-width-sm ml-2"}
                  customClick={handleDownloadInvoicePdf}
                />
              </div>
            </Col>
          </Row>
        </div>
      </Form>
    </div>
  );
}
