/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  Row,
  Col,
  Form,
  FormGroup,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";

import Cookies from "js-cookie";
import CartTitle from "../../components/CartTitle";
import InputText from "../../components/InputText";
import CustomButton from "../../components/CustomButton";
import UploadFile from "../../components/UploadFile";
import HsnCode from "../../utils/HsnCode.json";
import history from "../../history";

import { handleToast } from "../../utils/common";
import MeasurementUnit from "../../utils/measurementUnit.json";
import DescriptionList from "../../utils/descriptionList.json";

import { createProduct, updateProduct } from "../../redux/Hot_Products/actions";

function CreateProduct() {
  const dispatch = useDispatch();
  const [id, setId] = useState("");
  const [prodName, setProdName] = useState("");
  const [price, setPrice] = useState("");
  const [hsnCode, setHsnCode] = useState("");
  const [measurementUnit, setMeasurementUnit] = useState("");
  const [description, setDescription] = useState("");
  const [productAlias, setProductAlias] = useState("");
  const [status, setStatus] = useState("");
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [toggleImageModel, seToggleImageModel] = useState(false);
  const [image, setImage] = useState({ preview: null, Image: null });

  useEffect(() => {
    if (history.location.pathname === "/update-product") {
      const product = sessionStorage.getItem("update-product");
      const parsedProduct = JSON.parse(product);
      handleSetUpdateData(parsedProduct);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleProdNameChange = (e) => {
    setProdName(e.target.value);
  };

  const handlePriceChanges = (e) => {
    setPrice(e.target.value);
  };

  const handleStatusChange = (e) => {
    setStatus(e.target.value);
  };

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };

  const handleMeasurementUnit = (e) => {
    setMeasurementUnit(e.target.value);
  };

  const handleHsnCodeChanges = (e) => {
    setHsnCode(e.target.value);
  };

  const handleProductAlias = (e) => {
    setProductAlias(e.target.value);
  };

  const handleSetUpdateData = (data) => {
    let obj = {
      preview: data.image,
      image: null,
    };
    setProdName(data.name);
    setPrice(data.price);
    setHsnCode(data.hsnCode);
    setMeasurementUnit(data.measurementUnit);
    setDescription(data.description);
    setProductAlias(data.productAlias);
    setStatus(data.status);
    setSelectedRowData(data);
    setId(data.id);
    setImage(obj);
  };

  const handleImageChange = (e) => {
    e.preventDefault();
    var fileTypes = ["jpg", "jpeg", "png", "xlsx"];
    if (e.target.files[0]) {
      var extension = e.target.files[0].name.split(".").pop().toLowerCase(), //file extension from input file
        isSuccess = fileTypes.indexOf(extension) > -1;

      if (isSuccess) {
        let reader = new FileReader();
        let file = e.target.files[0];

        reader.onloadend = () => {
          saveImage(reader.result, file);
        };
        reader.readAsDataURL(file);
      } else {
        handleToast("only '.jpg' , '.jpeg' , '.png' file types are accepted");
      }
    } else {
    }

    e.target.value = "";
  };

  const handleShowImage = () => {
    seToggleImageModel(!toggleImageModel);
  };

//   const handleDownloadImage = (image) => {
//       const link = document.createElement('imageDownloadElement');
//       link.download = image.preview.split('/').pop() || 'downloaded-image';
//       link.href = image.preview;
//       document.body.appendChild(link);
//       link.click();
//       document.body.removeChild(link);
//   };

  const deleteImage = () => {
    const file = {
      preview: null,
      Image: null,
    };
    setImage(file);
  };

  const saveImage = (preview, Image) => {
    const file = {
      preview,
      Image,
    };
    setImage(file);
  };

  const handleCancel = () => {
    history.push("/product");
  };

  const cookieeData = Cookies.get("User");
  let parsedData = JSON.parse(cookieeData);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (prodName !== "") {
      if (price !== "") {
        if (hsnCode !== "") {
          if (measurementUnit !== "") {
            if (status !== "") {
              if (description !== "") {
                if (selectedRowData !== null) {
                  let data = {
                    id,
                    name: prodName,
                    price: Number(price),
                    hsnCode,
                    measurementUnit,
                    description,
                    status: Boolean(status),
                    image: image.Image !== null ? "image1" : image.preview,
                    imageForUpload: image.Image !== null ? image.Image : null,
                    productAlias,
                    vendorId: parsedData.vendorId,
                  };
                  dispatch(updateProduct(data));
                } else {
                  let data = {
                    name: prodName,
                    price: Number(price),
                    hsnCode,
                    measurementUnit,
                    description,
                    status: Boolean(status),
                    image: "image1",
                    imageForUpload: image.Image,
                    productAlias,
                    vendorId: parsedData.vendorId,
                  };
                  dispatch(createProduct(data));
                }
              } else {
                handleToast("Enter Description");
              }
            } else {
              handleToast("Select Status");
            }
          } else {
            handleToast("Enter Measurement Unit");
          }
        } else {
          handleToast("Enter HSN Code");
        }
      } else {
        handleToast("Enter Price");
      }
    } else {
      handleToast("Enter Product Name");
    }
  };

  return (
    <div>
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col sm={12}>
            <CartTitle title={"Products"} customBtnHide={true} />
          </Col>
        </Row>
        <div className="CardWithLightBg backgroundCard fullHeight rounded">
          <Row className="px-2 px-lg-4 py-4 pt-0">
            <Col lg={12} className="rightLine">
              <div
                className="imagePreview profileHeightLage"
                style={{
                  fontSize: "1.3rem",
                  fontWeight: "bold",
                  lineHeight: "1.5",
                  textDecoration: "capital",
                }}
              >
                <UploadFile
                  id="image1"
                  image={image}
                  handleImageChange={handleImageChange}
                  deleteImage={deleteImage}
                  uploadText={"Upload Image"}
                />
              </div>
            </Col>
            <Col lg={12}>
              <Row>
                <Col
                  lg={6}
                  className={`mb-lg-3 mb-sm-3 ${
                    prodName !== "" ? "valueActive" : ""
                  }`}
                >
                  <InputText
                    labelName={"Name"}
                    inputType={"text"}
                    inputName={"productName"}
                    tooltip={""}
                    customValue={prodName}
                    customOnChange={handleProdNameChange}
                  />
                </Col>

                <Col
                  lg={6}
                  className={`mb-lg-3 mb-sm-3 ${
                    productAlias !== "" ? "valueActive" : ""
                  }`}
                >
                  <InputText
                    labelName={"Print Name"}
                    inputType={"text"}
                    inputName={"productAlias"}
                    tooltip={""}
                    customValue={productAlias}
                    customOnChange={handleProductAlias}
                  />
                </Col>
              </Row>
              <Row>
                <Col
                  lg={6}
                  className={`mb-lg-3 mb-sm-3 ${
                    price !== "" ? "valueActive" : ""
                  }`}
                >
                  <InputText
                    labelName={"Price"}
                    inputType={"text"}
                    inputName={"price"}
                    tooltip={""}
                    customValue={price}
                    customOnChange={handlePriceChanges}
                  />
                </Col>
                <Col
                  lg={6}
                  className={`mb-lg-3 mb-sm-3 ${
                    hsnCode !== "" ? "valueActive" : ""
                  }`}
                >
                  <FormGroup>
                    <select
                      className="form-control"
                      name="hsnCode"
                      onChange={(e) => handleHsnCodeChanges(e)}
                      value={hsnCode}
                      style={{
                        fontWeight: "bold",
                        fontSize: "1.2rem",
                      }}
                    >
                      <option>Select HSN Code</option>

                      {HsnCode.map((item, index) => (
                        <option value={item}>{item}</option>
                      ))}
                    </select>
                    <span className="highlight" />
                    <span className="bar" />
                  </FormGroup>
                </Col>

                <Col
                  lg={6}
                  className={`mb-lg-3 mb-sm-3 ${
                    measurementUnit !== "" ? "valueActive" : ""
                  }`}
                >
                  <FormGroup>
                    <select
                      className="form-control"
                      name="measurementUnit"
                      onChange={(e) => handleMeasurementUnit(e)}
                      value={measurementUnit}
                      style={{
                        fontWeight: "bold",
                        fontSize: "1.2rem",
                      }}
                    >
                      <option>Select Units</option>

                      {MeasurementUnit.map((item, index) => (
                        <option value={item}>{item}</option>
                      ))}
                    </select>
                    <span className="highlight" />
                    <span className="bar" />
                  </FormGroup>
                </Col>
                <Col
                  lg={6}
                  className={`mb-lg-3 mb-sm-3 ${
                    description !== "" ? "valueActive" : ""
                  }`}
                >
                  <FormGroup>
                    <select
                      className="form-control"
                      name="description"
                      onChange={(e) => handleDescriptionChange(e)}
                      value={description}
                      style={{
                        fontWeight: "bold",
                        fontSize: "1.2rem",
                      }}
                    >
                      <option>Select Description</option>

                      {DescriptionList.map((item, index) => (
                        <option value={item}>{item}</option>
                      ))}
                    </select>
                    <span className="highlight" />
                    <span className="bar" />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col
                  lg={6}
                  className={`${status !== "" ? "valueActive" : ""}  mb-3`}
                >
                  <FormGroup>
                    <select
                      className="form-control"
                      name="status"
                      onChange={(e) => handleStatusChange(e)}
                      value={status}
                      style={{
                        fontWeight: "bold",
                        fontSize: "1.2rem",
                      }}
                    >
                      <option>Select Status</option>

                      <option value={true}>True</option>
                       <option value={false}>False</option>
                    </select>
                    <span className="highlight" />
                    <span className="bar" />
                  </FormGroup>
                </Col>
              </Row>
            </Col>
            <Col sm="12">
              <div className="d-flex justify-content-end mb-sm-0 mb-lg-4">
                <CustomButton
                  customType={"submit"}
                  name={selectedRowData !== null ? "Update Product" : "Submit"}
                  customClass={
                    "customButtonsmall bgSecondary mr-2 btn-width-sm"
                  }
                />



                {(selectedRowData?.image &&
                  selectedRowData?.image !== "image1") ||
                (selectedRowData === null && image.Image !== null) ? (
                  <CustomButton
                    customType={"button"}
                    name={"Show Image"}
                    customClass={
                      "customButtonsmall bgSecondary btn-width-sm ml-2"
                    }
                    customClick={() => handleShowImage()}
                  />
                ) : null}

{/*                 {(selectedRowData?.image && */}
{/*                   selectedRowData?.image !== "image1") || */}
{/*                 (selectedRowData === null && image.Image !== null) ? ( */}
{/*                   <CustomButton */}
{/*                     customType={"button"} */}
{/*                     name={"Download Image"} */}
{/*                     customClass={ */}
{/*                       "customButtonsmall bgSecondary btn-width-sm ml-2" */}
{/*                     } */}
{/*                     customClick={() => handleDownloadImage(image)} */}
{/*                   /> */}
{/*                 ) : null} */}

                 <CustomButton
                      customType={"button"}
                      name={"Cancel"}
                      customClass={"customButtonsmall bgSecondary btn-width-sm"}
                      customClick={() => handleCancel()}
                    />
              </div>
            </Col>
          </Row>
        </div>
      </Form>

      <Modal isOpen={toggleImageModel} toggle={handleShowImage}>
        <ModalHeader toggle={handleShowImage}>Product Image</ModalHeader>
        <ModalBody>
          <img
            src={image.preview !== null ? image.preview : ""}
            alt="Full Screen"
            className="img-fluid w-100 h-100"
            style={{ objectFit: "cover" }}
          />
        </ModalBody>
      </Modal>
    </div>
  );
}

export default CreateProduct;
