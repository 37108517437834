import * as types from "./actionType";

const initialState = {
  createSupplier: false,
  updateSupplier: false,
  allSuppliers: [],
  allSuppliersStatus: false,
  deleteSupplier: false,
};

function supplierReducer(state = initialState, action, email) {
  switch (action.type) {
    case types.CREATE_SUPPLIER:
      return {
        ...state,
        createSupplier: true,
      };
    case types.CREATE_SUPPLIER_SUCCESS: {
      return {
        ...state,
        createSupplier: false,
      };
    }
    case types.CREATE_SUPPLIER_FAILED: {
      return {
        ...state,
        createSupplier: false,
      };
    }
    case types.FETCH_SUPPLIER:
      return {
        ...state,
        allSuppliers: [],
        allSuppliersStatus: true,
      };
    case types.FETCH_SUPPLIER_SUCCESS:
      return {
        ...state,
        allSuppliers: action.payload,
        allSuppliersStatus: false,
      };
    case types.FETCH_SUPPLIER_FAILED:
      return {
        ...state,
        allSuppliers: [],
        allSuppliersStatus: false,
      };
    case types.DELETE_SUPPLIER:
      return {
        ...state,
        deleteSupplier: true,
      };
    case types.DELETE_SUPPLIER_SUCCESS:
      return {
        ...state,
        deleteSupplier: false,
      };
    case types.DELETE_SUPPLIER_FAILED:
      return {
        ...state,
        deleteSupplier: false,
      };

    case types.UPDATE_SUPPLIER:
      return {
        ...state,
        updateSupplier: true,
      };
    case types.UPDATE_SUPPLIER_SUCCESS:
      return {
        ...state,
        updateSupplier: false,
      };
    case types.UPDATE_SUPPLIER_FAILED:
      return {
        ...state,
        updateSupplier: false,
      };

    default:
      return state;
  }
}

export default supplierReducer;
