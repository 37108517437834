import history from "../../history";
import * as types from "./actionType";
import API from "../../utils/endpoints";

import { handleToast } from "../../utils/common";

export function fetchingUser() {
  return {
    type: types.GET_ALL_USER,
  };
}

export function fetchingUserSuccess(payload) {
  return {
    type: types.GET_ALL_USER_SUCCESS,
    payload,
  };
}

export function fetchingUserFailed(payload) {
  return {
    type: types.GET_ALL_USER_FAILED,
    payload,
  };
}

export function creatingUser() {
  return {
    type: types.CREATE_USER,
  };
}

export function creatingUserSuccess(payload) {
  return {
    type: types.CREATE_USER_SUCCESS,
    payload,
  };
}

export function creatingUserFailed(payload) {
  return {
    type: types.CREATE_USER_FAILED,
    payload,
  };
}

export function updatingUser() {
  return {
    type: types.UPDATE_USER,
  };
}

export function updatingUserSuccess(payload) {
  return {
    type: types.UPDATE_USER_SUCCESS,
    payload,
  };
}

export function updatingUserFailed(payload) {
  return {
    type: types.UPDATE_USER_FAILED,
    payload,
  };
}

export function deletingUser() {
  return {
    type: types.DELETE_USER,
  };
}

export function deletingUserSuccess(payload) {
  return {
    type: types.DELETE_USER_SUCCESS,
    payload,
  };
}

export function deletingUserFailed(payload) {
  return {
    type: types.DELETE_USER_FAILED,
    payload,
  };
}

//****** get all User   ********//

export const fetchAllUser = (payload) => async (dispatch, getState, api) => {
  dispatch(fetchingUser());
  try {
    const response = await api.get(API.fetchAllUser);
    if (response.data.code === 200) {
      dispatch(fetchingUserSuccess(response.data.res));
    } else {
      dispatch(fetchingUserFailed([]));
    }
  } catch (error) {
//    console.log({ error });
    let message = error.response.data.message;
    handleToast(message);
    dispatch(fetchingUserFailed([]));
  }
};

//****** create User  ********//

export const createUser = (payload) => async (dispatch, getState, api) => {
  dispatch(creatingUser());
  try {
    const response = await api.post(API.createUser, payload);
    if (response.data.code === 200) {
      dispatch(creatingUserSuccess(response.data.data));
      handleToast(response.data.message);
      history.push("/user-list");
    } else {
      handleToast(response.data.message);
      dispatch(creatingUserFailed([]));
    }
  } catch (error) {
//    console.log({ error });
    let message = error.response.data.message;
    handleToast(message);
    dispatch(creatingUserFailed([]));
  }
};

//****** update User   ********//

export const updateUser = (payload) => async (dispatch, getState, api) => {
  dispatch(updatingUser());

  try {
    const response = await api.put(`${API.updateUser}/${payload.id}`, payload);

    if (response.data.code === 200) {
      dispatch(updatingUserSuccess(response.data.data));
      handleToast(response.data.message);
      history.push("/user-list");
    } else {
      handleToast(response.data.message);
      dispatch(updatingUserFailed([]));
    }
  } catch (error) {
//    console.log({ error });
    let message = error.response.data.message;
    handleToast(message);
    dispatch(updatingUserFailed([]));
  }
};

//****** delete User   ********//

export const deleteUser = (payload) => async (dispatch, getState, api) => {
  dispatch(deletingUser());
  try {
    let response = await api.delete(`${API.deleteUser}/${payload.id}`);
    if (response.data.code === 200) {
      handleToast(response.data.message);
      dispatch(deletingUserSuccess(response.data.data));
      dispatch(fetchAllUser());
    } else {
      handleToast(response.data.message);
      dispatch(deletingUserFailed([]));
    }
  } catch (error) {
//    console.log({ error });
    let message = error.response.data.message;
    handleToast(message);
    dispatch(deletingUserFailed([]));
  }
};
