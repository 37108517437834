import * as types from "./actionType";

const initialState = {
  loggedInUser: null,
  loginStatus: false,
  mobileNumberForOtp: null,
  loginUserId: null,
  sendOtpData: null,
  sendOtpStatus: false,
  loginWithMobileData: null,
  loginWithMobileStatus: false,
  userData: null,
  userDataStatus: false,
};

function authReducer(state = initialState, action, email) {
  switch (action.type) {
    case types.LOGIN_USER:
      return {
        ...state,
        loginStatus: true,
        loggedInUser: null,
        loginUserId: null,
        mobileNumberForOtp: null,
      };
    case types.LOGIN_USER_SUCCESS: {
      return {
        ...state,
        loginStatus: false,
        loggedInUser: action.payload,
        loginUserId: action.userId,
        mobileNumberForOtp: action.mobileNumber,
      };
    }
    case types.LOGIN_USER_FAILED: {
      return {
        ...state,
        loginStatus: false,
        loggedInUser: null,
        loginUserId: null,
        mobileNumberForOtp: null,
      };
    }
    case types.SEND_OTP:
      return {
        ...state,
        sendOtpData: null,
        sendOtpStatus: true,
      };
    case types.SEND_OTP_SUCCESS:
      return {
        ...state,
        sendOtpData: action.payload,
        sendOtpStatus: false,
      };
    case types.SEND_OTP_FAILED:
      return {
        ...state,
        sendOtpData: null,
        sendOtpStatus: false,
      };
    case types.LOGIN_WITH_MOBILE:
      return {
        ...state,
        loginWithMobileData: null,
        loginWithMobileStatus: true,
      };
    case types.LOGIN_WITH_MOBILE_SUCCESS:
      return {
        ...state,
        loginWithMobileData: action.payload,
        loginWithMobileStatus: false,
      };
    case types.LOGIN_WITH_MOBILE_FAILED:
      return {
        ...state,
        loginWithMobileData: null,
        loginWithMobileStatus: false,
      };

    case types.GET_USER_DATA:
      return {
        ...state,
        userData: null,
        userDataStatus: true,
      };
    case types.GET_USER_DATA_SUCCESS:
      return {
        ...state,
        userData: action.payload,
        userDataStatus: false,
      };
    case types.GET_USER_DATA_FAILED:
      return {
        ...state,
        userData: null,
        userDataStatus: false,
      };

    default:
      return state;
  }
}

export default authReducer;
