import * as types from "./actionType";

const initialState = {
  createUserStatus: false,
  updateUserStatus: false,
  deleteUserStatus: false,
  allUserStatus: false,
  allUser: [],
};

function userReducer(state = initialState, action) {
  switch (action.type) {
    case types.GET_ALL_USER:
      return {
        ...state,
        allUserStatus: true,
        allUser: [],
      };
    case types.GET_ALL_USER_SUCCESS:
      return {
        ...state,
        allUserStatus: false,
        allUser: action.payload,
      };

    case types.GET_ALL_USER_FAILED:
      return {
        ...state,
        allUserStatus: false,
        allUser: [],
      };

    case types.CREATE_USER:
      return {
        ...state,
        createUserStatus: true,
      };
    case types.CREATE_USER_SUCCESS:
      return {
        ...state,
        createUserStatus: false,
      };

    case types.CREATE_USER_FAILED:
      return {
        ...state,
        createUserStatus: false,
      };

    case types.UPDATE_USER:
      return {
        ...state,
        updateUserStatus: true,
      };
    case types.UPDATE_USER_SUCCESS:
      return {
        ...state,
        updateUserStatus: false,
      };

    case types.UPDATE_USER_FAILED:
      return {
        ...state,
        updateUserStatus: false,
      };

    case types.DELETE_USER:
      return {
        ...state,
        deleteUserStatus: true,
      };
    case types.DELETE_USER_SUCCESS:
      return {
        ...state,
        deleteUserStatus: false,
      };

    case types.DELETE_USER_FAILED:
      return {
        ...state,
        deleteUserStatus: false,
      };

    default:
      return state;
  }
}

export default userReducer;
