import React from "react"; 
import CustomButton from "../../components/CustomButton"

function CartTitle(props) {
  return (
    <React.Fragment>
      <div className="d-flex justify-content-between light-border-bottom bgLight mb-4 align-items-center p-2 rounded titleWrapper mt-4">
        <div className="CartTitle d-flex align-items-center">
          <h5 className="text-capitalize m-0 pl-2">

            <span style={{
                                  color: "#29388d",
                                  fontSize: "1.3rem",
                                  fontWeight: "bold",
                                  lineHeight: "1.5",
                                  textDecoration: "capital",
                                }}>{props.title}</span>
          </h5>
        </div>

        <div hidden={props.customBtnHide}>

          <CustomButton
            customType={"button"}
            name={props.customBtnName}
            customClass={"customButtonsmall bgSecondary btn-width-sm"}
            customClick={props.customBtnClick}
          />

        </div>
      </div>
    </React.Fragment >
  );
}

export default CartTitle;
