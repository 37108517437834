import React from "react";
import { Button } from "reactstrap";
import "../../assets/scss/components/Button/index.scss";

function CustomButton(props) {
  return (
    <React.Fragment>
      <div className=" ">
        <Button
          type={props.customType}
          className={`cornerBg ${props.customClass}`}
          onClick={props.customClick}
          disabled={props.custonDisable}
          style={{
                      fontWeight: 'bold',
                      fontSize: "1.1rem",
                      }}
        >
          <span className="d-block">{props.name}</span>
        </Button>
      </div>
    </React.Fragment>
  );
}

export default CustomButton;
