import React, { Component } from "react";
import MaterialTable from "material-table";
import "../../assets/scss/components/InputText/index.scss";

export default class index extends Component {
  render() {
    return (
      <React.Fragment>
        <div>
          <MaterialTable
            title=""
            style={{
              fontSize: "1rem",
            }}
            columns={this.props.columns}
            data={this.props.data}
            actions={[
              {
                icon: "assignment_ind",
                tooltip: this.props.tooltip,
                onClick: (e, selectedRowData) =>
                  this.props.onRowEdit(selectedRowData),
                hidden: this.props.hidden1,
              },
              {
                icon: "assignment_ind",
                tooltip: this.props.tooltip2,
                onClick: (e, selectedRowData) =>
                  this.props.onRowEdit2(selectedRowData),
                hidden: this.props.hidden2,
              },
              {
                icon: "create",
                tooltip: this.props.tooltip3,
                onClick: (e, selectedRowData) =>
                  this.props.onRowEdit3(selectedRowData),
                hidden: this.props.hidden3,
              },
            ]}
            options={{
              actionsColumnIndex: -1,
              exportButton: true,
              pageSize: 10,
              headerStyle: {
                color: "#29388d",
                fontWeight: "bold" /* Make text bold */,
                fontSize: "1.3rem" /* Font size */,
              },
              searchFieldStyle: {
                color: "#29388d",
                fontWeight: "bold" /* Make text bold */,
                fontSize: "1.3rem" /* Font size */,
              },
            }}
            editable={{
              onRowDelete: this.props.onRowDelete,
            }}
          />
        </div>
      </React.Fragment>
    );
  }
}
