import React, { Component } from "react";
import Cookies from "js-cookie";
import { Redirect, withRouter } from "react-router-dom"; 

export default (ChildComponent) => {
  class RequireAuth extends Component {
    render() {
      let token = Cookies.get("SP_DASH"); 
      if (!token) {
        return <Redirect to="/login" />;
      } else {
        return <ChildComponent {...this.props} />;
      }
    }
  }

  return withRouter(RequireAuth);
};
