import React from "react";
import { Link } from "react-router-dom";
import Cookies from "js-cookie";
import { Icon } from "react-icons-kit";
import { home } from "react-icons-kit/icomoon/home";

import { Nav, NavItem } from "reactstrap";
import adminJson from "../../utils/admin.json";
import userJson from "../../utils/user.json";
import history from "../../history";

const Navigation = (props) => {
  const cookieeData = Cookies.get("User");
  let parsedData = JSON.parse(cookieeData);

  const handleSideNavToggle = () => {
    document.body.classList.toggle("aside-active");
  };

  return (
    <React.Fragment>
      <Nav vertical>
        {parsedData?.userType === "admin"
          ? adminJson.map((item, index) => (
              <NavItem  key={index}>
                <div onClick={handleSideNavToggle}>
                  <Link
                    className={`nav-link ${
                      item.route === history.location.pathname ? "active" : ""
                    }`}
                    to={item.route}
                   
                  >
                    <Icon size={"26"} icon={home} className="mr-4" />
                    <span style={{
                                                                fontSize: "1.2rem",
                                                                fontWeight: "bold",
                                                              }}>{item.name}</span>
                  </Link>
                </div>
              </NavItem>
            ))
          : userJson.map((item, index) => (
              <NavItem key={index}>
                <div onClick={handleSideNavToggle}>
                  <Link
                    className={`nav-link ${
                      item.route === history.location.pathname ? "active" : ""
                    }`}
                    to={item.route}
                    key={index}
                  >
                    <Icon size={"26"} icon={home} className="mr-4" />
                    <span style={{
                                                                fontSize: "1.2rem",
                                                                fontWeight: "bold",
                                                              }}>{item.name}</span>
                  </Link>
                </div>
              </NavItem>
            ))}
      </Nav>
    </React.Fragment>
  );
};

export default Navigation;
