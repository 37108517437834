/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from "react";
import { Row, Col } from "reactstrap";
import Cookies from "js-cookie";
import moment from "moment/moment";
import { useDispatch, useSelector, shallowEqual } from "react-redux";

import CartTitle from "../../components/CartTitle";
import ListingTable from "../../components/listingTable";
import Loader from "../../components/Loader";
import history from "../../history";

import { deleteInvoice, getAllInvoices } from "../../redux/Bill-Invoice/actions";

export default function Invoice() {
  const [user, setUser] = useState(null);
  const columns = [
    {
      title: "Invoice Date",
      field: "invoiceDate",
    },
    {
      title: "Invoice No",
      field: "billNumber",
    },
    {
      title: "Supplier Name",
      field: "vendorName",
    },
    {
      title: "Total Amount",
      field: "invoiceTotalDTO.totalAmount",
    },
    {
      title: "Transport",
      field: "transport",
    },
  ];

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllInvoices());
    sessionStorage.removeItem("barcode");
    sessionStorage.removeItem("invoices");
    const userData = Cookies.get("User");
    const parsedData = JSON.parse(userData);

    setUser(parsedData);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { allInvoice, allInvoiceStatus } = useSelector(
    (state) => state.Invoice,
    shallowEqual
  );

  const handleRoutetoCreateForm = () => {
    history.push("/create-invoice");
  };

  const handleDeleteRowFromTable = (oldData) =>
    new Promise((resolve) => {
      setTimeout(() => {
        resolve();
        dispatch(deleteInvoice(oldData));
      }, 600);
    });

  const handleOnRowEditFromTable = (data) => {
    let route = history.location.pathname;

    if (route === "/invoices") {
      history.push("/invoices-details");
      sessionStorage.setItem("invoices", JSON.stringify(data));
    } else {
      history.push("/barcode-print");
      sessionStorage.setItem("barcode", JSON.stringify(data));
    }
  };

  const handleOnRowEditFromTable2 = (data) => {
    history.push("/invoices-details");
    sessionStorage.setItem("invoices", JSON.stringify(data));
  };

  const handleOnRowEditFromTable3 = (data) => {
    history.push("/update-invoice");
    sessionStorage.setItem("invoices", JSON.stringify(data));
  };

  return (
    <div
      style={{
        color: "#29388d",
        fontWeight: "bold",
        lineHeight: "1.5",
      }}
    >
      {allInvoiceStatus && <Loader />}
      <Row>
        <Col sm={12}>
          <CartTitle
            title={"Invoices"}
            customBtnName={"Create Invoice"}
            customBtnClick={handleRoutetoCreateForm}
            customBtnHide={user?.userType === "admin" ? true : false}
          />
        </Col>
      </Row>
      <Row>
        <Col sm="12">
          <div className="backgroundCard fullHeight CardWithLightBg rounded">
            {history.location.pathname === "/invoices" ? (
              <ListingTable
                data={allInvoice}
                columns={columns}
                onRowDelete={handleDeleteRowFromTable}
                onRowEdit={handleOnRowEditFromTable}
                tooltip={"Detail Page"}
                hidden2={true}
                hidden3={true}
              />
            ) : (
              <ListingTable
                data={allInvoice}
                columns={columns}
                onRowDelete={handleDeleteRowFromTable}
                onRowEdit={handleOnRowEditFromTable}
                onRowEdit2={handleOnRowEditFromTable2}
                onRowEdit3={handleOnRowEditFromTable3}
                tooltip={"Print Barcode"}
                tooltip2={"Detail Page"}
                tooltip3={"Edit Invoice"}
              />
            )}
          </div>
        </Col>
      </Row>
    </div>
  );
}
