import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import { ToWords } from "to-words";
import moment from "moment/moment";
import {
  Row,
  Col,
  Form,
  FormGroup,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import DescriptionList from "../../utils/descriptionList.json";

import history from "../../history";

import InputText from "../../components/InputText";
import CartTitle from "../../components/CartTitle";
import CustomButton from "../../components/CustomButton";
import UploadFile from "../../components/UploadFile";

import {
  getAllProducts,
  createProduct,
} from "../../redux/Hot_Products/actions";

import { createInvoice, updateInvoice } from "../../redux/Bill-Invoice/actions";

import { handleToast } from "../../utils/common";
import MeasurementUnit from "../../utils/measurementUnit.json";
import Transport from "../../utils/transport.json";
import GstList from "../../utils/gst.json";
import HsnCode from "../../utils/HsnCode.json";

const staticDeductions = ["Discount", "Add amount", "Less amount"];

// const staticDeductions = [
//   { adjustmentType: "Discount", disablePercentage: false },
//   { adjustmentType: "Add amount", disablePercentage: true },
//   { adjustmentType: "Less amount", disablePercentage: t },
// ];

export default function CreateInvoice(props) {
  const dispatch = useDispatch();
  const [id, setId] = useState();
  const [modelToggle, setModelToggle] = useState(false);
  const [productAlias, setProductAlias] = useState("");
  const [image, setImage] = useState({ preview: null, Image: null });
  const [prodName, setProdName] = useState("");
  const [price, setPrice] = useState("");
  const [hsnCode, setHsnCode] = useState("");
  const [measurementUnit, setMeasurementUnit] = useState("");
  const [description, setDescription] = useState("");
  const [status, setStatus] = useState("");
  const [gst, setGst] = useState("");
  const [gstCalculationArray, setGstCalculationArray] = useState([]);
  const [transport, setTransport] = useState("");
  const [narration, setNarration] = useState("");
  const [billNumber, setBillNumber] = useState("");
  const [caseNumber, setCaseNumber] = useState("");
  const [amountAfterEachAdjustment, setAmountAfterEachAdjustment] = useState(0);
  const [totalAmountAfterDeduction, setTotalAmountAfterDeduction] =
    useState("");
  const [
    totalAmountAfterDeductionInWords,
    setTotalAmountAfterDeductionInWords,
  ] = useState("");

  const [roundOff, setRoundOff] = useState("");

  const [product, setProduct] = useState([
    {
      id: null,
      invoiceId: null,
      productId: null,
      quantity: 1,
      price: null,
      total: null,
      productName: "",
    },
  ]);
  const [deductions, setDeductions] = useState([
    {
      adjustmentId: null,
      invoiceId: null,
      adjustmentType: "",
      percentage: null,
      amount: null,
      narration: "",
      finalamountAfterCalcultion: 0,
    },
  ]);
  const [invoiceDate, setInvoiceDate] = useState("");

  useEffect(() => {
    if (history.location.pathname === "/update-invoice") {
      const invoicesData = sessionStorage.getItem("invoices");
      const parsedInvoice = JSON.parse(invoicesData);
      handleUpdateInvoice(parsedInvoice);
    }
    //     const today = new Date().toISOString().split("T")[0];
    const today = new Date()
      .toLocaleString("en-IN", {
        timeZone: "Asia/Kolkata",
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      })
      .split("/")
      .reverse()
      .join("-");
    setInvoiceDate(today);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDateChange = (e) => {
    setInvoiceDate(e.target.value);
    // Additional logic if needed
  };

  useEffect(() => {
    dispatch(getAllProducts());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { allProduct } = useSelector((state) => state.Product, shallowEqual);

  //Update

  const handleUpdateInvoice = (invoicesData) => {
    let datatoMap = invoicesData.productList.map((item) => ({
      id: item.id,
      invoiceId: item.invoiceId,
      productId: item.productId,
      quantity: item.quantity,
      price: item.price,
      total: item.price * item.quantity,
      productName: item.productName,
    }));

    let gstData = invoicesData.invoiceAdjustmentDTO.filter(
      (value) => value.adjustmentType === "GST"
    );

    let adjustmentData = invoicesData.invoiceAdjustmentDTO.filter(
      (value) => value.adjustmentType !== "GST"
    );

//     console.log({ adjustmentData });

    setGstCalculationArray(gstData);
    setProduct(datatoMap);
    setId(invoicesData.invoiceId);
    setGst(invoicesData.gst);
    setNarration(invoicesData.narration);
    setBillNumber(invoicesData.billNumber);
    setCaseNumber(invoicesData.caseNumber);
    setTransport(invoicesData.transport);
    setDeductions(adjustmentData.length ? adjustmentData : deductions);
    setTotalAmountAfterDeduction(invoicesData.invoiceTotalDTO.totalAmount);
    setTotalAmountAfterDeductionInWords(
      invoicesData.invoiceTotalDTO.totalInWords
    );
    setRoundOff(invoicesData.invoiceTotalDTO.roundOff);
  };

  // Add New Product

  const handleProdNameChange = (e) => {
    setProdName(e.target.value);
  };

  const handlePriceChanges = (e) => {
    setPrice(e.target.value);
  };

  const handleStatusChange = (e) => {
    setStatus(e.target.value);
  };

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };

  const handleMeasurementUnit = (e) => {
    setMeasurementUnit(e.target.value);
  };

  const handleHsnCodeChanges = (e) => {
    setHsnCode(e.target.value);
  };
  const handleProductAlias = (e) => {
    setProductAlias(e.target.value);
  };

  const handleCancel = () => {
    setProdName("");
    setPrice("");
    setHsnCode("");
    setMeasurementUnit("");
    setDescription("");
    setStatus("");
    setImage({ preview: null, Image: null });
  };

  const handleImageChange = (e) => {
    e.preventDefault();
    var fileTypes = ["jpg", "jpeg", "png", "xlsx"];
    if (e.target.files[0]) {
      var extension = e.target.files[0].name.split(".").pop().toLowerCase(), //file extension from input file
        isSuccess = fileTypes.indexOf(extension) > -1;

      if (isSuccess) {
        let reader = new FileReader();
        let file = e.target.files[0];

        reader.onloadend = () => {
          saveImage(reader.result, file);
        };
        reader.readAsDataURL(file);
      } else {
        handleToast("only '.jpg' , '.jpeg' , '.png' file types are accepted");
      }
    } else {
    }

    e.target.value = "";
  };

  const deleteImage = () => {
    const file = {
      preview: null,
      Image: null,
    };
    setImage(file);
  };

  const saveImage = (preview, Image) => {
    const file = {
      preview,
      Image,
    };
    setImage(file);
  };

  // Products

  const handleGstCalculation = (value) => {


    let lastIndex = deductions[deductions.length - 1];
    let finalAmount;
    if (lastIndex.finalamountAfterCalcultion === 0) {
      let total = product.reduce(
        (accumulator, current) => accumulator + current.total,
        0
      );
      finalAmount = total;
      setAmountAfterEachAdjustment(total);
    } else {
      finalAmount = lastIndex.finalamountAfterCalcultion;
    }
    console.log("finalAmount:", finalAmount);
    let amountAfterGstCalculation;
    let finalArrayForGstCalculation;
    if (value.gstType === "local") {
      const amountAfterGstCalculation =
        Math.round((value.totalPercentage / 100) * finalAmount * 100) / 100;

      finalArrayForGstCalculation = [
        {
          sno: 1,
          adjustmentType: "GST",
          narration: value.cgst.narration,
          percentage: value.cgst.percentage,
          amount: amountAfterGstCalculation / 2,
          adjustmentId: null,
          invoiceId: null,
          finalamountAfterCalcultion: amountAfterGstCalculation + finalAmount,
        },
        {
          sno: 2,
          adjustmentType: "GST",
          narration: value.sgst.narration,
          percentage: value.sgst.percentage,
          amount: amountAfterGstCalculation / 2,
          adjustmentId: null,
          invoiceId: null,
          finalamountAfterCalcultion: amountAfterGstCalculation + finalAmount,
        },
      ];
    } else {
      amountAfterGstCalculation = (value.percentage / 100) * finalAmount;
      amountAfterGstCalculation =
        Math.round((value.percentage / 100) * finalAmount * 100) / 100;

      finalArrayForGstCalculation = [
        {
          sno: 1,
          adjustmentType: "GST",
          narration: value.narration,
          percentage: value.percentage,
          amount: amountAfterGstCalculation,
          adjustmentId: null,
          invoiceId: null,
          finalamountAfterCalcultion: finalAmount + amountAfterGstCalculation,
        },
      ];
    }
    setGstCalculationArray(finalArrayForGstCalculation);
    handleTotalAmountAfterAdjustments(finalArrayForGstCalculation);
  };

  const handleGstChange = (e) => {
    const parsedValue = JSON.parse(e.target.value);
    setGst(parsedValue.gstName);
    handleGstCalculation(parsedValue);
  };

  const handleTransportChange = (e) => {
    setTransport(e.target.value);
  };

  const handleNarrationChange = (e) => {
    setNarration(e.target.value);
  };

  const handleBillNumberChange = (e) => {
    setBillNumber(e.target.value);
  };

  const handleCaseNumberChange = (e) => {
    setCaseNumber(e.target.value);
  };

  const handleAddNewProductModel = () => {
    setModelToggle(!modelToggle);
  };

  const handleProductSelection = (e, index) => {
    let parsedData = JSON.parse(e.target.value);
    let data = [...product];
    data[index]["productId"] = parsedData.id;
    data[index]["quantity"] = 1;
    data[index]["price"] = parsedData.price;
    data[index]["total"] = parsedData.price;
    data[index]["productName"] = parsedData.name;
    setProduct(data);
    setDeductions([
      {
        adjustmentId: null,
        invoiceId: null,
        adjustmentType: "",
        percentage: null,
        amount: null,
        narration: "",
        finalamountAfterCalcultion: 0,
      },
    ]);
    setTotalAmountAfterDeductionInWords("");
    setTotalAmountAfterDeduction("");
    setGstCalculationArray([]);
    setGst("");
    setRoundOff("");
    handleTotalAmountAfterAdjustments(deductions);
  };

  const handleProductChange = (e, index) => {
    let data = [...product];
    if (e.target.name === "quantity") {
      let numberQuantity = Number(e.target.value);
      let priceNumber = Number(data[index].price);
      data[index]["quantity"] = numberQuantity;
      data[index]["total"] = priceNumber * numberQuantity;
    }
    if (e.target.name === "price") {
      let priceNumber = Number(e.target.value);
      let numberQuantity = Number(data[index].quantity);
      data[index]["price"] = priceNumber;
      data[index]["total"] = priceNumber * numberQuantity;
    }
    setProduct(data);
    setDeductions([
      {
        adjustmentId: null,
        invoiceId: null,
        adjustmentType: "",
        percentage: null,
        amount: null,
        narration: "",
        finalamountAfterCalcultion: 0,
      },
    ]);
    setTotalAmountAfterDeductionInWords("");
    setTotalAmountAfterDeduction("");
    setGstCalculationArray([]);
    setGst("");
    setRoundOff("");
  };

  const handleAddProduct = () => {
    let newfield = {
      id: null,
      invoiceId: null,
      productId: null,
      quantity: null,
      price: null,
      total: null,
      productName: "",
    };

    setProduct([...product, newfield]);
    setDeductions([
      {
        adjustmentId: null,
        invoiceId: null,
        adjustmentType: "",
        percentage: null,
        amount: null,
        narration: "",
        finalamountAfterCalcultion: 0,
      },
    ]);
    setTotalAmountAfterDeductionInWords("");
    setTotalAmountAfterDeduction("");
    setGstCalculationArray([]);
    setGst("");
    setRoundOff("");
  };

  const handleRemoveProduct = (e, index) => {
    let data = [...product];
    data.splice(index, 1);
    setProduct(data);
    setDeductions([
      {
        adjustmentId: null,
        invoiceId: null,
        adjustmentType: "",
        percentage: null,
        amount: null,
        narration: "",
        finalamountAfterCalcultion: 0,
      },
    ]);
    setTotalAmountAfterDeductionInWords("");
    setTotalAmountAfterDeduction("");
    setGstCalculationArray([]);
    setGst("");
    setRoundOff("");
  };

  // Deductions

  const handleAddDeduction = () => {
    let newfield = {
      adjustmentId: null,
      invoiceId: null,
      adjustmentType: "",
      percentage: null,
      amount: null,
      narration: "",
    };

    setDeductions([...deductions, newfield]);
    setGstCalculationArray([]);
    setTotalAmountAfterDeductionInWords("");
    setTotalAmountAfterDeduction("");
    setGst("");
    setRoundOff("");
  };

  const handleRemoveDeductions = (e, index) => {
    let data = [...deductions];
    data.splice(index, 1);
    if (index === 0) {
      setDeductions([
        {
          adjustmentId: null,
          invoiceId: null,
          adjustmentType: "",
          percentage: null,
          amount: null,
          narration: "",
          finalamountAfterCalcultion: 0,
        },
      ]);
    } else {
      setDeductions(data);
    }
    setGstCalculationArray([]);
    setTotalAmountAfterDeductionInWords("");
    setTotalAmountAfterDeduction("");
    setGst("");
    setRoundOff("");
  };

  const handleDeductionChange = (e, index) => {
    let data = [...deductions];
    let finalAmountToSetState;

    if (index + 1 < deductions.length) {
      let indexToRemove = deductions.length - (index + 1);
      let splicedData = data.slice(0, -indexToRemove);
      data = splicedData;
      setDeductions(data);
      setTotalAmountAfterDeductionInWords("");
      setTotalAmountAfterDeduction("");
    }

    if (e.target.name === "adjustmentType") {
      data[index]["adjustmentType"] = e.target.value;
      let amount = product.reduce((prev, current) => {
        return prev + +current.total;
      }, 0);
      setAmountAfterEachAdjustment(amount);
    }
    if (e.target.name === "narration") {
      data[index]["narration"] = e.target.value;
    }
    if (e.target.name === "percentage") {
      if (data[index].adjustmentType === "Add amount") {
        let percentNumber = Number(e.target.value);
        data[index]["percentage"] = percentNumber;
        if (index === 0) {
          let amountAfterCalulation =
            (percentNumber / 100) * amountAfterEachAdjustment.toFixed(2);
          data[index]["amount"] = amountAfterCalulation;
          finalAmountToSetState =
            amountAfterCalulation + amountAfterEachAdjustment;
          data[index]["finalamountAfterCalcultion"] = finalAmountToSetState;
        } else {
          let previousAmount = data[index - 1].finalamountAfterCalcultion;
          let amountAfterCalulation =
            (percentNumber / 100) * previousAmount.toFixed(2);
          data[index]["amount"] = amountAfterCalulation;
          finalAmountToSetState = amountAfterCalulation + previousAmount;
          data[index]["finalamountAfterCalcultion"] = finalAmountToSetState;
        }
      }
      if (data[index].adjustmentType === "Discount") {
        let percentNumber = e.target.value;
        data[index]["percentage"] = percentNumber;
        if (index === 0) {
          let amountAfterCalulation =
            (percentNumber / 100) * amountAfterEachAdjustment.toFixed(2);
          data[index]["amount"] = amountAfterCalulation.toFixed(2);
          finalAmountToSetState =
            amountAfterEachAdjustment - amountAfterCalulation;
          data[index]["finalamountAfterCalcultion"] = finalAmountToSetState;
        } else {
          let previousAmount = data[index - 1].finalamountAfterCalcultion;
          let amountAfterCalulation =
            (percentNumber / 100) * previousAmount.toFixed(2);
          data[index]["amount"] = amountAfterCalulation.toFixed(2);
          finalAmountToSetState = previousAmount - amountAfterCalulation;
          data[index]["finalamountAfterCalcultion"] = finalAmountToSetState;
        }
      }
      if (data[index].adjustmentType === "Less amount") {
        let percentNumber = Number(e.target.value);
        data[index]["percentage"] = percentNumber;
        if (index === 0) {
          let amountAfterCalulation =
            (percentNumber / 100) * amountAfterEachAdjustment.toFixed(2);
          data[index]["amount"] = amountAfterCalulation;
          finalAmountToSetState =
            amountAfterEachAdjustment - amountAfterCalulation;
          data[index]["finalamountAfterCalcultion"] = finalAmountToSetState;
        } else {
          let previousAmount = data[index - 1].finalamountAfterCalcultion;
          let amountAfterCalulation =
            (percentNumber / 100) * previousAmount.toFixed(2);
          data[index]["amount"] = amountAfterCalulation;
          finalAmountToSetState = previousAmount - amountAfterCalulation;
          data[index]["finalamountAfterCalcultion"] = finalAmountToSetState;
        }
      }
    }
    if (e.target.name === "amount") {
      if (data[index].adjustmentType === "Add amount") {
        if (index === 0) {
          data[index]["amount"] = Number(e.target.value);
          finalAmountToSetState =
            Number(e.target.value) + amountAfterEachAdjustment;
          data[index]["finalamountAfterCalcultion"] = finalAmountToSetState;
        } else {
          let previousAmount = data[index - 1].finalamountAfterCalcultion;
          data[index]["amount"] = Number(e.target.value);
          finalAmountToSetState = Number(e.target.value) + previousAmount;
          data[index]["finalamountAfterCalcultion"] = finalAmountToSetState;
        }
      }
      if (data[index].adjustmentType === "Less amount") {
        if (index === 0) {
          data[index]["amount"] = Number(e.target.value);
          finalAmountToSetState =
            amountAfterEachAdjustment - Number(e.target.value);
          data[index]["finalamountAfterCalcultion"] = finalAmountToSetState;
        } else {
          let previousAmount = data[index - 1].finalamountAfterCalcultion;
          data[index]["amount"] = Number(e.target.value);
          finalAmountToSetState = previousAmount - Number(e.target.value);
          data[index]["finalamountAfterCalcultion"] = finalAmountToSetState;
        }
      }
    }
    setDeductions(data);
    setGstCalculationArray([]);
    setGst("");
    setRoundOff("");
    handleTotalAmountAfterAdjustments(data);
  };

  const handleTotalAmountAfterAdjustments = (finalArrayForGstCalculation) => {
    const toWords = new ToWords();
    let amount = finalArrayForGstCalculation.slice(-1)[0];
    if (amount.finalamountAfterCalcultion === 0) {
      let total = product.reduce(
        (accumulator, current) => accumulator + current.price,
        0
      );
      let roundOffAmount = Math.round(total);
      let roundOffFigure = Math.round((roundOffAmount - total) * 100) / 100;
      setRoundOff(roundOffFigure);
      setTotalAmountAfterDeduction(roundOffAmount);
      let NumericAmount = Number(roundOffAmount);
      if (total) {
        let inWords = toWords.convert(NumericAmount);
        setTotalAmountAfterDeductionInWords(inWords);
      }
    } else {
      let roundOffAmount = Math.round(amount.finalamountAfterCalcultion);
      let NumericAmount = Number(roundOffAmount);
      let roundOffFigure =
        Math.round((roundOffAmount - amount.finalamountAfterCalcultion) * 100) /
        100;
      setRoundOff(roundOffFigure);
      if (amount.finalamountAfterCalcultion) {
        let inWords = toWords.convert(NumericAmount);
        setTotalAmountAfterDeductionInWords(inWords);
      }
      setTotalAmountAfterDeduction(NumericAmount);
    }
  };

  //   console.log({roundOff})
  //   console.log({ product }, { deductions });

  //Submit

  const handleSubmit = (e) => {
    e.preventDefault();
    const cookieeData = Cookies.get("User");
    let parsedData = JSON.parse(cookieeData);
    let deductionSent = deductions.slice(-1)[0];
    if (gst !== "") {
      if (transport !== "") {
        if (product.length !== 0) {
          let data = {
            userID: parsedData.id,
            invoiceId: id,
            invoiceDate: moment(invoiceDate).format("DD-MM-YYYY"),
            gst: gst,
            caseNumber: caseNumber,
            transport: transport,
            narration: narration,
            billNumber: billNumber,
            totalAmount: amountAfterEachAdjustment,
            productList: product,
            invoiceAdjustmentDTO:
              deductionSent.finalamountAfterCalcultion === 0
                ? [...gstCalculationArray]
                : [...deductions, ...gstCalculationArray],
            invoiceTotalDTO: {
              totalId: null,
              invoiceId: null,
              totalAmount: totalAmountAfterDeduction,
              totalInWords: totalAmountAfterDeductionInWords,
              roundOff: roundOff,
            },
          };
            console.log("data: ", data);
          if (history.location.pathname === "/update-invoice") {
            dispatch(updateInvoice(data));
          } else {
            dispatch(createInvoice(data));
          }
          // console.log({ data });
        } else {
          handleToast("Select Product");
        }
      } else {
        handleToast("Enter Transport");
      }
    } else {
      handleToast("Enter Vendor Gst Correctly");
    }
  };

  const cookieeData = Cookies.get("User");
  let parsedData = JSON.parse(cookieeData);
  const handleSumbitProductData = (e) => {
    e.preventDefault();
    if (prodName !== "") {
      if (price !== "") {
        if (hsnCode !== "") {
          if (measurementUnit !== "") {
            if (status !== "") {
              if (description !== "") {
                let data = {
                  name: prodName,
                  price: Number(price),
                  hsnCode,
                  measurementUnit,
                  description,
                  status: Boolean(status),
                  image: "image1",
                  imageForUpload: image.Image,
                  productAlias: productAlias,
                  vendorId: parsedData.vendorId,
                };

                dispatch(createProduct(data));
                handleCancel();
                handleAddNewProductModel();
                setProductAlias("");
              } else {
                handleToast("Enter Description");
              }
            } else {
              handleToast("Select Status");
            }
          } else {
            handleToast("Enter Measurement Unit");
          }
        } else {
          handleToast("Enter HSN Code");
        }
      } else {
        handleToast("Enter Price");
      }
    } else {
      handleToast("Enter Product Name");
    }
  };

  const handleGoBack = () => {
    history.push("/bill-list");
  };

  return (
    <div>
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col sm={12}>
            <CartTitle title={"Invoice"} customBtnHide={true} />
          </Col>
        </Row>
        <div className=" backgroundCard CardWithLightBg rounded">
          <Row className=" px-2 px-lg-4 py-4">
            <Col
              lg={4}
              className={`${invoiceDate !== "" ? "valueActive" : ""}  mb-3`}
            >
              <FormGroup>
                <InputText
                  labelName={"Select Date"}
                  inputType={"date"}
                  inputName={"invoiceDate"}
                  customValue={invoiceDate}
                  customOnChange={(e) => handleDateChange(e)}
                />
              </FormGroup>
            </Col>

            <Col
              lg={4}
              className={`${billNumber !== "" ? "valueActive" : ""}  mb-3`}
            >
              <InputText
                labelName={"Invoice Number"}
                inputType={"text"}
                inputName={"billNumber"}
                tooltip={""}
                customValue={billNumber}
                customOnChange={(e) => handleBillNumberChange(e)}
              />
            </Col>

            <Col
              lg={4}
              className={`${caseNumber !== "" ? "valueActive" : ""}  mb-3`}
            >
              <InputText
                labelName={"Case Number"}
                inputType={"text"}
                inputName={"caseNumber"}
                tooltip={""}
                customValue={caseNumber}
                customOnChange={(e) => handleCaseNumberChange(e)}
              />
            </Col>
            <Col
              lg={4}
              className={`mb-lg-3 mb-sm-3 ${
                transport !== "" ? "valueActive" : ""
              }`}
            >
              <FormGroup>
                <select
                  className="form-control"
                  name="transport"
                  onChange={(e) => handleTransportChange(e)}
                  value={transport}
                  style={{
                    fontWeight: "bold",
                    fontSize: "1.2rem",
                  }}
                >
                  <option>Select Transport</option>

                  {Transport.map((item, index) => (
                    <option value={item}>{item}</option>
                  ))}
                </select>
                <span className="highlight" />
                <span className="bar" />
              </FormGroup>
            </Col>
            <Col
              lg={4}
              className={`${narration !== "" ? "valueActive" : ""}  mb-3`}
            >
              <InputText
                labelName={"Narration"}
                inputType={"text"}
                inputName={"narration"}
                tooltip={""}
                customValue={narration}
                customOnChange={(e) => handleNarrationChange(e)}
              />
            </Col>
          </Row>
        </div>

        <Row>
          <Col sm={12}>
            <CartTitle title={"Products"} customBtnHide={true} />
          </Col>
        </Row>
        <div className=" backgroundCard CardWithLightBg rounded">
          {product.map((item, index) => (
            <Row className=" px-2 px-lg-4 py-4">
              <Col lg={2} className={`valueActive mb-3`}>
                <InputText
                  labelName={"S No"}
                  inputType={"text"}
                  inputName={"quantity"}
                  tooltip={""}
                  customValue={index + 1}
                />
              </Col>
              <Col
                lg={2}
                className={`${
                  item.productId !== null ? "valueActive" : ""
                }  mb-3`}
              >
                <FormGroup>
                  <select
                    className="form-control"
                    name="productId"
                    onChange={(e) => handleProductSelection(e, index)}
                    style={{
                      fontWeight: "bold",
                      fontSize: "1.2rem",
                    }}
                  >
                    <option>
                      {item.productName !== ""
                        ? item.productName
                        : "Select Product"}
                    </option>
                    {allProduct?.map((item, index) => (
                      <option value={JSON.stringify(item)}>{item.name}</option>
                    ))}
                  </select>
                  <span className="highlight" />
                  <span className="bar" />
                </FormGroup>
              </Col>
              <Col
                lg={2}
                className={`${
                  item.quantity !== null ? "valueActive" : ""
                }  mb-3`}
              >
                <InputText
                  labelName={"Quantity"}
                  inputType={"text"}
                  inputName={"quantity"}
                  tooltip={""}
                  customValue={item.quantity}
                  customOnChange={(e) => handleProductChange(e, index)}
                />
              </Col>
              <Col
                lg={2}
                className={`${item.price !== null ? "valueActive" : ""}  mb-3`}
              >
                <InputText
                  labelName={"Price (RS)"}
                  inputType={"text"}
                  inputName={"price"}
                  tooltip={""}
                  customValue={item.price}
                  customOnChange={(e) => handleProductChange(e, index)}
                />
              </Col>
              <Col
                lg={2}
                className={`${item.total !== null ? "valueActive" : ""}  mb-3`}
              >
                <InputText
                  labelName={"Amount"}
                  inputType={"text"}
                  inputName={"total"}
                  tooltip={""}
                  customValue={item.total}
                  customOnChange={(e) => handleProductChange(e, index)}
                />
              </Col>
              <Col lg={2} className={` mb-3`}>
                <CustomButton
                  customType={"button"}
                  name={"Remove"}
                  customClass={"customButtonsmall bgPrimary btn-width-sm"}
                  customClick={(e) => handleRemoveProduct(e, index)}
                />
              </Col>
            </Row>
          ))}
          <Col sm="12">
            <div className="d-flex justify-content-end mb-sm-0 mb-lg-4">
              <CustomButton
                customType={"button"}
                name={"Add New Product"}
                customClass={"customButtonsmall bgPrimary btn-width-sm"}
                customClick={handleAddNewProductModel}
              />
              <CustomButton
                customType={"button"}
                name={"Add Product"}
                customClass={"customButtonsmall bgPrimary btn-width-sm ml-2"}
                customClick={handleAddProduct}
              />
            </div>
          </Col>
        </div>

        <Row>
          <Col sm={12}>
            <CartTitle title={"Adjustments"} customBtnHide={true} />
          </Col>
        </Row>
        <div className=" backgroundCard CardWithLightBg rounded">
          {deductions.map((item, index) => (
            <Row className=" px-2 px-lg-4 py-4">
              <Col lg={2} className={`valueActive mb-3`}>
                <InputText
                  labelName={"S No"}
                  inputType={"text"}
                  inputName={"quantity"}
                  tooltip={""}
                  customValue={index + 1}
                />
              </Col>
              <Col
                lg={2}
                className={`${
                  item.adjustmentType !== null ? "valueActive" : ""
                }  mb-3`}
              >
                <FormGroup>
                  <select
                    className="form-control"
                    name="adjustmentType"
                    onChange={(e) => handleDeductionChange(e, index)}
                    value={item.adjustmentType}
                    style={{
                      fontWeight: "bold",
                      fontSize: "1.2rem",
                    }}
                  >
                    <option>Select Adjustments</option>
                    {staticDeductions?.map((item, index) => (
                      <option value={item}>{item}</option>
                    ))}
                  </select>
                  <span className="highlight" />
                  <span className="bar" />
                </FormGroup>
              </Col>
              <Col
                lg={2}
                className={`${
                  item.narration !== "" ? "valueActive" : ""
                }  mb-3`}
              >
                <InputText
                  labelName={"Narration"}
                  inputType={"text"}
                  inputName={"narration"}
                  tooltip={""}
                  customValue={item.narration}
                  customOnChange={(e) => handleDeductionChange(e, index)}
                />
              </Col>
              <Col
                lg={2}
                className={`${
                  item.percentage !== null ? "valueActive" : ""
                }  mb-3`}
              >
                <InputText
                  labelName={"Percentage %"}
                  inputType={"text"}
                  inputName={"percentage"}
                  tooltip={""}
                  customValue={item.percentage !== null ? item.percentage : ""}
                  customOnChange={(e) => handleDeductionChange(e, index)}
                  disabledType={item.adjustmentType !== "Discount"}
                />
              </Col>
              <Col
                lg={2}
                className={`${item.amount !== null ? "valueActive" : ""}  mb-3`}
              >
                <InputText
                  labelName={"Amount"}
                  inputType={"text"}
                  inputName={"amount"}
                  tooltip={""}
                  customValue={item.amount !== null ? item.amount : ""}
                  customOnChange={(e) => handleDeductionChange(e, index)}
                />
              </Col>
              <Col lg={2} className={` mb-3`}>
                <CustomButton
                  customType={"button"}
                  name={"Remove"}
                  customClass={"customButtonsmall bgPrimary btn-width-sm"}
                  customClick={(e) => handleRemoveDeductions(e, index)}
                />
              </Col>
            </Row>
          ))}
          <Col sm="12">
            <div className="d-flex justify-content-end mb-sm-0 mb-lg-4">
              <CustomButton
                customType={"button"}
                name={"Add Adjustment"}
                customClass={"customButtonsmall bgPrimary btn-width-sm"}
                customClick={handleAddDeduction}
              />
            </div>
          </Col>
        </div>

        <Row>
          <Col sm={12}>
            <CartTitle title={"GST"} customBtnHide={true} />
          </Col>
        </Row>
        <div className=" backgroundCard CardWithLightBg rounded">
          <Row className=" px-2 px-lg-4 py-4">
            <Col lg={4} className={`${gst !== "" ? "valueActive" : ""}  mb-3`}>
              <FormGroup>
                <div>
                  <select
                    className="form-control"
                    name="gst"
                    onChange={(e) => handleGstChange(e)}
                    value={gst}
                    style={{
                      fontWeight: "bold",
                      fontSize: "1.2rem",
                    }}
                  >
                    <option>{gst !== "" ? gst : "Select GST Type"}</option>

                    {GstList.map((item, index) => (
                      <option value={JSON.stringify(item)}>
                        {item.gstName}
                      </option>
                    ))}
                  </select>
                </div>
                <span className="highlight" />
                <span className="bar" />
              </FormGroup>
            </Col>
          </Row>

          {gstCalculationArray.map((item, index) => (
            <Row className=" px-2 px-lg-4 py-4">
              <>
                <Col
                  lg={4}
                  className={`${
                    item.narration !== "" ? "valueActive" : ""
                  }  mb-3`}
                >
                  <InputText
                    labelName={"Narration"}
                    inputType={"text"}
                    inputName={"narration"}
                    tooltip={""}
                    customValue={item.narration}
                  />
                </Col>
                <Col
                  lg={4}
                  className={`${
                    item.percentage !== null ? "valueActive" : ""
                  }  mb-3`}
                >
                  <InputText
                    labelName={"Percentage %"}
                    inputType={"text"}
                    inputName={"percentage"}
                    tooltip={""}
                    customValue={
                      item.percentage !== null ? item.percentage : ""
                    }
                  />
                </Col>
                <Col
                  lg={4}
                  className={`${
                    item.amount !== null ? "valueActive" : ""
                  }  mb-3`}
                >
                  <InputText
                    labelName={"Amount"}
                    inputType={"text"}
                    inputName={"amount"}
                    tooltip={""}
                    customValue={item.amount !== null ? item.amount : ""}
                  />
                </Col>
              </>
            </Row>
          ))}
        </div>

        <Row>
          <Col sm={12}>
            <CartTitle title={"Summary"} customBtnHide={true} />
          </Col>
        </Row>
        <div className=" backgroundCard CardWithLightBg rounded">
          <Row className=" px-2 px-lg-4 py-4">
            <Col
              lg={4}
              className={`${roundOff !== "" ? "valueActive" : ""}  mb-3`}
            >
              <InputText
                labelName={"Round Off Amount"}
                inputType={"text"}
                inputName={"roundOff"}
                tooltip={""}
                customValue={roundOff}
              />
            </Col>{" "}
            <Col
              lg={4}
              className={`${
                totalAmountAfterDeduction !== "" ? "valueActive" : ""
              }  mb-3`}
            >
              <InputText
                labelName={"Total Amount"}
                inputType={"text"}
                inputName={"totalAmountAfterDeduction"}
                tooltip={""}
                customValue={totalAmountAfterDeduction}
              />
            </Col>{" "}
            <Col
              lg={4}
              className={`${
                totalAmountAfterDeductionInWords !== "" ? "valueActive" : ""
              }  mb-3`}
            >
              <InputText
                labelName={"Total Amount In Words"}
                inputType={"text"}
                inputName={"totalAmountAfterDeductionInWords"}
                tooltip={""}
                customValue={totalAmountAfterDeductionInWords}
              />
            </Col>{" "}
            <Col sm="12">
              <div className="d-flex justify-content-end mb-sm-0 mb-lg-4">
                {/* <CustomButton
                  customType={"button"}
                  name={"Calculate"}
                  customClass={
                    "customButtonsmall bgSecondary mr-2 btn-width-sm"
                  }
                  customClick={handleTotalAmountAfterAdjustments}
                /> */}
                <CustomButton
                  customType={"submit"}
                  name={"Submit"}
                  customClass={
                    "customButtonsmall bgSecondary mr-2 btn-width-sm"
                  }
                />
                <CustomButton
                  customType={"button"}
                  name={"Cancel"}
                  customClass={"customButtonsmall bgPrimary btn-width-sm"}
                  customClick={handleGoBack}
                />
              </div>
            </Col>
          </Row>
        </div>
      </Form>

      <Modal isOpen={modelToggle}>
        <ModalHeader toggle={handleAddNewProductModel}>Product</ModalHeader>
        <ModalBody>
          <Row className="px-2 px-lg-4 py-4 pt-0">
            <Col lg={12} className="rightLine">
              <div className="imagePreview">
                <UploadFile
                  id="image1"
                  image={image}
                  handleImageChange={handleImageChange}
                  deleteImage={deleteImage}
                  uploadText={"Upload Image"}
                />
              </div>
            </Col>
          </Row>

          <Row className="px-2 px-lg-4 py-4 pt-0">
            <Col lg={12}>
              <Row>
                <Col
                  lg={6}
                  className={`mb-lg-3 mb-sm-3 ${
                    prodName !== "" ? "valueActive" : ""
                  }`}
                >
                  <InputText
                    labelName={"Name"}
                    inputType={"text"}
                    inputName={"productName"}
                    tooltip={""}
                    customValue={prodName}
                    customOnChange={handleProdNameChange}
                  />
                </Col>
                <Col
                  lg={6}
                  className={`mb-lg-3 mb-sm-3 ${
                    productAlias !== "" ? "valueActive" : ""
                  }`}
                >
                  <InputText
                    labelName={"Print Name"}
                    inputType={"text"}
                    inputName={"productAlias"}
                    tooltip={""}
                    customValue={productAlias}
                    customOnChange={handleProductAlias}
                  />
                </Col>
              </Row>
              <Row>
                <Col
                  lg={6}
                  className={`mb-lg-3 mb-sm-3 ${
                    price !== "" ? "valueActive" : ""
                  }`}
                >
                  <InputText
                    labelName={"Price"}
                    inputType={"text"}
                    inputName={"price"}
                    tooltip={""}
                    customValue={price}
                    customOnChange={handlePriceChanges}
                  />
                </Col>

                <Col
                  lg={6}
                  className={`mb-lg-3 mb-sm-3 ${
                    hsnCode !== "" ? "valueActive" : ""
                  }`}
                >
                  <FormGroup>
                    <select
                      className="form-control"
                      name="hsnCode"
                      onChange={(e) => handleHsnCodeChanges(e)}
                      value={hsnCode}
                      style={{
                        fontWeight: "bold",
                        fontSize: "1.2rem",
                      }}
                    >
                      <option>Select HSN Code</option>

                      {HsnCode.map((item, index) => (
                        <option value={item}>{item}</option>
                      ))}
                    </select>
                    <span className="highlight" />
                    <span className="bar" />
                  </FormGroup>
                </Col>

                <Col
                  lg={6}
                  className={`mb-lg-3 mb-sm-3 ${
                    measurementUnit !== "" ? "valueActive" : ""
                  }`}
                >
                  <FormGroup>
                    <select
                      className="form-control"
                      name="measurementUnit"
                      onChange={(e) => handleMeasurementUnit(e)}
                      value={measurementUnit}
                      style={{
                        fontWeight: "bold",
                        fontSize: "1.2rem",
                      }}
                    >
                      <option>Select Units</option>

                      {MeasurementUnit.map((item, index) => (
                        <option value={item}>{item}</option>
                      ))}
                    </select>
                    <span className="highlight" />
                    <span className="bar" />
                  </FormGroup>
                </Col>
                <Col
                  lg={6}
                  className={`mb-lg-3 mb-sm-3 ${
                    description !== "" ? "valueActive" : ""
                  }`}
                >
                  <FormGroup>
                    <select
                      className="form-control"
                      name="description"
                      onChange={(e) => handleDescriptionChange(e)}
                      value={description}
                      style={{
                        fontWeight: "bold",
                        fontSize: "1.2rem",
                      }}
                    >
                      <option>Select Description</option>

                      {DescriptionList.map((item, index) => (
                        <option value={item}>{item}</option>
                      ))}
                    </select>
                    <span className="highlight" />
                    <span className="bar" />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col
                  lg={6}
                  className={`${status !== "" ? "valueActive" : ""}  mb-3`}
                >
                  <FormGroup>
                    <select
                      className="form-control"
                      name="applicationFormType"
                      onChange={(e) => handleStatusChange(e)}
                      value={status}
                      style={{
                        fontWeight: "bold",
                        fontSize: "1.2rem",
                      }}
                    >
                      <option>Select Status</option>

                      <option value={true}>True</option>
                      <option value={false}>False</option>
                    </select>
                    <span className="highlight" />
                    <span className="bar" />
                  </FormGroup>
                </Col>
              </Row>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Col sm="12">
            <div className="d-flex justify-content-end mb-sm-0 mb-lg-4">
              <CustomButton
                customType={"submit"}
                name={"Submit"}
                customClass={"customButtonsmall bgSecondary mr-2 btn-width-sm"}
                customClick={handleSumbitProductData}
              />
            </div>
          </Col>
        </ModalFooter>
      </Modal>
    </div>
  );
}
