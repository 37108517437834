import history from "../../history";
import Cookies from "js-cookie";
import * as types from "./actionType";
import API from "../../utils/endpoints";

import { handleToast } from "../../utils/common";

export function loggingUser() {
  return {
    type: types.LOGIN_USER,
  };
}

export function loggingUserSuccess(payload, userId, mobileNumber) {
  return {
    type: types.LOGIN_USER_SUCCESS,
    payload,
    userId,
    mobileNumber,
  };
}

export function loggingUserFailed(payload) {
  return {
    type: types.LOGIN_USER_FAILED,
    payload,
  };
}

function sendOtp() {
  return {
    type: types.SEND_OTP,
  };
}

function sendOtpSuccess(payload) {
  return {
    type: types.SEND_OTP_SUCCESS,
    payload,
  };
}

function sendOtpFailed(payload) {
  return {
    type: types.SEND_OTP_FAILED,
    payload,
  };
}

function loginWithMobile() {
  return {
    type: types.LOGIN_WITH_MOBILE,
  };
}

function loginWithMobileSuccess(payload) {
  return {
    type: types.LOGIN_WITH_MOBILE_SUCCESS,
    payload,
  };
}

function loginWithMobileFailed(payload) {
  return {
    type: types.LOGIN_WITH_MOBILE_FAILED,
    payload,
  };
}

function gettingUserData() {
  return {
    type: types.GET_USER_DATA,
  };
}

function gettingUserDataSuccess(payload) {
  return {
    type: types.GET_USER_DATA_SUCCESS,
    payload,
  };
}

function gettingUserDataFailed(payload) {
  return {
    type: types.GET_USER_DATA_FAILED,
    payload,
  };
}

export const loginUser = (payload) => async (dispatch, getState, api) => {
  try {
    dispatch(loggingUser());
    let data = {
      email: payload.userName,
      password: payload.password,
    };
    const response = await api.post(API.loginUser, data);
    if (response.data.code === 200) {
      let accessToken = response.data.res.token;
      Cookies.set("SP_DASH", `Bearer ${accessToken}`);
      await dispatch(
        loggingUserSuccess(
          accessToken,
          response.data.res.userId,
          response.data.res.mobileNumber
        )
      );
      await dispatch(sendOtpForLogin(response.data.res.userId));
      history.push("/otp-verify");
    } else {
      dispatch(loggingUserFailed(response.data));
      handleToast(response.data.message);
    }
  } catch (error) {
//    console.log({ error });
    let message = error.response.data;
    handleToast(message);
    dispatch(loggingUserFailed());
  }
};

export const sendOtpForLogin = (payload) => async (dispatch, getState, api) => {
  dispatch(sendOtp());
  try {
    const response = await api.get(`${API.sendOtpForLogin}/${payload}`);
    if (response.data.code === 200) {
      let message = response.data.message;
      handleToast(message);
      dispatch(sendOtpSuccess(response.data));
    } else {
      let message = response.data.message;
      handleToast(message);
      dispatch(sendOtpFailed(response.data));
    }
  } catch (error) {
//    console.log({ error });
    let message = error.response.data.message;
    handleToast(message);
    dispatch(sendOtpFailed(error));
  }
};

export const loginWithMobileOtp =
  (payload) => async (dispatch, getState, api) => {
    dispatch(loginWithMobile());
    try {
      let userId = getState().Auth.loginUserId;
      if (!userId) {
        history.push("/login");
      }
      const response = await api.get(
        `${API.loginWithMobileOtp}/${userId}/${payload}`
      );
      if (response.data.code === 200) {
        dispatch(loginWithMobileSuccess(response.data.data));
        dispatch(getUserData(userId));
      } else {
        let message = response.data.message;
        handleToast(message);
        dispatch(loginWithMobileFailed(response.data.data));
      }
    } catch (error) {
//      console.log({ error });
      let message = error.response.data.message;
      handleToast(message);
      dispatch(loginWithMobileFailed(error));
    }
  };

export const getUserData = (payload) => async (dispatch, getState, api) => {
  dispatch(gettingUserData());
  try {
    const response = await api.get(`${API.getUserData}/${payload}`);
    if (response.data.code === 200) {
      Cookies.set("User", JSON.stringify(response.data.res));
      dispatch(gettingUserDataSuccess(response.data.res));
      if (response.data.res.userType === "admin") {
        history.push("/supplier-list");
      } else {
        history.push("/bill-list");
      }
    } else {
      dispatch(gettingUserDataFailed(response.data.res));
    }
  } catch (error) {
//    console.log({ error });
    let message = error.response.data.message;
    handleToast(message);
    dispatch(gettingUserDataFailed(error));
  }
};
