export const GET_INVOICES = "GET_INVOICES";
export const GET_INVOICES_SUCCESS = "GET_INVOICES_SUCCESS";
export const GET_INVOICES_FAILED = "GET_INVOICES_FAILED";

export const CREATE_INVOICES = "CREATE_INVOICES";
export const CREATE_INVOICES_SUCCESS = "CREATE_INVOICES_SUCCESS";
export const CREATE_INVOICES_FAILED = "CREATE_INVOICES_FAILED";

export const UPDATE_INVOICES = "UPDATE_INVOICES";
export const UPDATE_INVOICES_SUCCESS = "UPDATE_INVOICES_SUCCESS";
export const UPDATE_INVOICES_FAILED = "UPDATE_INVOICES_FAILED";

export const DELETE_INVOICES = "DELETE_INVOICES";
export const DELETE_INVOICES_SUCCESS = "DELETE_INVOICES_SUCCESS";
export const DELETE_INVOICES_FAILED = "DELETE_INVOICES_FAILED";

export const PRINT_BARCODE = "PRINT_BARCODE";
export const PRINT_BARCODE_SUCCESS = "PRINT_BARCODE_SUCCESS";
export const PRINT_BARCODE_FAILED = "PRINT_BARCODE_FAILED";

export const DOWNLOAD_PDF_INVOICE = "DOWNLOAD_PDF_INVOICE";
export const DOWNLOAD_PDF_INVOICE_SUCCESS = "DOWNLOAD_PDF_INVOICE_SUCCESS";
export const DOWNLOAD_PDF_INVOICE_FAILED = "DOWNLOAD_PDF_INVOICE_FAILED";