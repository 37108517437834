import * as types from "./actionType";

const initialState = {
  createInvoiceStatus: false,
  updateInvoiceStatus: false,
  deleteInvoiceStatus: false,
  allInvoiceStatus: false,
  allInvoice: [],
  printBarcodeStatus: false,
  barcodePdf: null,
  invoicePdf: null,
};

function invoiceReducer(state = initialState, action) {
  switch (action.type) {
    case types.GET_INVOICES:
      return {
        ...state,
        allInvoiceStatus: true,
        allInvoice: [],
      };
    case types.GET_INVOICES_SUCCESS:
      return {
        ...state,
        allInvoiceStatus: false,
        allInvoice: action.payload,
      };

    case types.GET_INVOICES_FAILED:
      return {
        ...state,
        allInvoiceStatus: false,
        allInvoice: [],
      };

    case types.CREATE_INVOICES:
      return {
        ...state,
        createInvoiceStatus: true,
      };
    case types.CREATE_INVOICES_SUCCESS:
      return {
        ...state,
        createInvoiceStatus: false,
      };

    case types.CREATE_INVOICES_FAILED:
      return {
        ...state,
        createInvoiceStatus: false,
      };

    case types.UPDATE_INVOICES:
      return {
        ...state,
        updateInvoiceStatus: true,
      };
    case types.UPDATE_INVOICES_SUCCESS:
      return {
        ...state,
        updateInvoiceStatus: false,
      };

    case types.UPDATE_INVOICES_FAILED:
      return {
        ...state,
        updateInvoiceStatus: false,
      };

    case types.DELETE_INVOICES:
      return {
        ...state,
        deleteInvoiceStatus: true,
      };
    case types.DELETE_INVOICES_SUCCESS:
      return {
        ...state,
        deleteInvoiceStatus: false,
      };

    case types.DELETE_INVOICES_FAILED:
      return {
        ...state,
        deleteInvoiceStatus: false,
      };

    case types.PRINT_BARCODE:
      return {
        ...state,
        printBarcodeStatus: true,
        barcodePdf: null,
      };
    case types.PRINT_BARCODE_SUCCESS:
      return {
        ...state,
        printBarcodeStatus: false,
        barcodePdf: action.payload,
      };

    case types.PRINT_BARCODE_FAILED:
      return {
        ...state,
        printBarcodeStatus: false,
        barcodePdf: null,
      };
    case types.DOWNLOAD_PDF_INVOICE:
      return {
        ...state,
        invoicePdf: null,
      };
    case types.DOWNLOAD_PDF_INVOICE_SUCCESS:
      return {
        ...state,
        invoicePdf: action.payload,
      };

    case types.DOWNLOAD_PDF_INVOICE_FAILED:
      return {
        ...state,
        invoicePdf: null,
      };
    default:
      return state;
  }
}

export default invoiceReducer;
