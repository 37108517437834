//****** LOGIN  ********//
export const CREATE_SUPPLIER = "CREATE_SUPPLIER";
export const CREATE_SUPPLIER_SUCCESS = "CREATE_SUPPLIER_SUCCESS";
export const CREATE_SUPPLIER_FAILED = "CREATE_SUPPLIER_FAILED";

export const FETCH_SUPPLIER = "FETCH_SUPPLIER";
export const FETCH_SUPPLIER_SUCCESS = "FETCH_SUPPLIER_SUCCESS";
export const FETCH_SUPPLIER_FAILED = "FETCH_SUPPLIER_FAILED";

export const DELETE_SUPPLIER = "DELETE_SUPPLIER";
export const DELETE_SUPPLIER_SUCCESS = "DELETE_SUPPLIER_SUCCESS";
export const DELETE_SUPPLIER_FAILED = "DELETE_SUPPLIER_FAILED";

export const UPDATE_SUPPLIER = "UPDATE_SUPPLIER";
export const UPDATE_SUPPLIER_SUCCESS = "UPDATE_SUPPLIER_SUCCESS";
export const UPDATE_SUPPLIER_FAILED = "UPDATE_SUPPLIER_FAILED";