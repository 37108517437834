import React from "react";
import { Label, Input, FormGroup } from "reactstrap";
import { ic_file_upload } from "react-icons-kit/md/ic_file_upload";
import Icon from "react-icons-kit";
import { ic_info_outline } from "react-icons-kit/md/ic_info_outline";
import { ic_search } from "react-icons-kit/md/ic_search";
import "../../assets/scss/components/InputText/index.scss";

function InputText(props) {
  return (
    <React.Fragment>
      <FormGroup className="inputWrpper customInputBox">
        <div className="tooltip-ex" hidden={props.customHidden}>
          <Icon size={18} icon={ic_info_outline} />
          <span className="tooltip-ex-text">
            <span>{props.tooltip}</span>
          </span>
        </div>
        <Input
          type={props.inputType}
          name={props.inputName}
          placeholder={props.inputPlaceholder}
          value={props.customValue}
          disabled={props.disabledType}
          onChange={props.customOnChange}
          className={props.InputClass}
          id={props.InputId}
          autoComplete="off"
          maxLength={props.customMaxlength}
          style={{
            fontWeight: 'bold',
            fontSize: "1.2rem",
            }}
        />
        <Label for={props.forName} style={{
                                     fontWeight: 'bold',
                                     fontSize: "1.2rem",
                                   }}>{props.labelName}</Label>
        <Icon size={"20"} className="search_icon icons" icon={ic_search} />
        <Icon size={"20"} className="upload_icon icons" icon={ic_file_upload} />
      </FormGroup>
    </React.Fragment>
  );
}
export default InputText;
