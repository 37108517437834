import React from "react";

import {FormGroup, Badge, Row } from "reactstrap";

import Icon from "react-icons-kit";

import { trashO } from "react-icons-kit/fa/trashO";
import { plus } from "react-icons-kit/fa/plus";

function uploadFile(props) {
  return (
    <React.Fragment>
      <div className="img-upload-thumb-row">
        <FormGroup>
          <Row className="position-relative">
            <label
              for={props.id}
              type="file"
              name="file"
              id="exampleFile"
              className={`uploadLabel ${
                props.image.preview !== null ? "zIndex-0" : "zIndex-1"
              } m-auto`}
            >
              <Badge className="striped_border bg-white badge badge-secondary position-relative">
                <Icon
                  icon={plus}
                  trashO
                  className="v-align-middle p-28 transform-center PlusIcon"
                  style={{ color: "#a6a6a6" }}
                />
                <span className="uplopadText txtLight ">
                  {props.uploadText}
                </span>
              </Badge>
            </label>

            <div className="hvrbox d-inline-block">
              <img
                src={props.image.preview !== null ? props.image.preview : ""}
                style={{ height: "150px", width: "150px" }}
                alt=""
              />
              <div className="hvrbox-layer_top">
                <div className="hvrbox-text transform-center position-relative w-100 h-100">
                  <Icon
                    onClick={() => props.deleteImage()}
                    icon={trashO}
                    size={40}
                    className="v-align-middle transform-center"
                    style={{ color: "#fff" }}
                  />
                </div>
              </div>
            </div>

            <input
              type="file"
              name="myfile"
              id={props.id}
              style={{ display: "none" }}
              onChange={(e) => props.handleImageChange(e)}
            />
          </Row>
        </FormGroup>
      </div>
    </React.Fragment>
  );
}
export default uploadFile;
