import React, { useEffect, useState } from "react";
import { Row, Col, Form, FormGroup } from "reactstrap";
import { useDispatch, useSelector, shallowEqual } from "react-redux";

import InputText from "../../components/InputText";
import CartTitle from "../../components/CartTitle";
import CustomButton from "../../components/CustomButton";

import { emailRegx, numberRegx } from "../../utils/regx";
import { handleToast } from "../../utils/common";

import history from "../../history";
import { fetchSupplier } from "../../redux/Supplier/actions";
import { createUser, updateUser } from "../../redux/User/actions";

export default function CreateUser() {
  const dispatch = useDispatch();
  const [id, setId] = useState("");
  const [fName, setFname] = useState("");
  const [lName, setLname] = useState("");
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPasswordl] = useState("");
  const [usertype, setUsertype] = useState("");
  const [status, setStatus] = useState("");
  const [mobile, setMobile] = useState("");
  const [supplier, setSupplier] = useState("");
  const [supplierName, setSupplierName] = useState("");

  useEffect(() => {
    if (history.location.pathname === "/user-update") {
      const user = sessionStorage.getItem("update-user");
      const parsedUser = JSON.parse(user);
      handleSetUpdateData(parsedUser);
    }
  }, []);

  useEffect(() => {
    dispatch(fetchSupplier());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { allSuppliers } = useSelector((state) => state.Supplier, shallowEqual);

  const handleSetUpdateData = (parsedUser) => {
    setId(parsedUser.id);
    setFname(parsedUser.firstName);
    setLname(parsedUser.lastName);
    setFullName(parsedUser.firstName+' '+parsedUser.lastName)
    setEmail(parsedUser.email);
    setPasswordl(parsedUser.password);
    setUsertype(parsedUser.userType);
    setStatus(parsedUser.status);
    setMobile(parsedUser.mobile);
    setSupplier(parsedUser.vendorId);
    setSupplierName(parsedUser.vendorName);
  };

  const handleFnameChange = (e) => {
    setFname(e.target.value);
  };

  const handleLnameChange = (e) => {
    setLname(e.target.value);
  };

 const handleFullNameChange = (e) => {
   setFullName(e.target.value);
 };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPasswordl(e.target.value);
  };

//   const handleUserTypeChange = (e) => {
//     setUsertype(e.target.value);
//   };
const handleUserTypeChange = (e) => {
  const selectedUserType = e.target.value;
  setUsertype(selectedUserType);

  if (selectedUserType === "admin") {
    setSupplier("");  // Set supplier to empty if user type is admin
  }
};


  const handleStatusChange = (e) => {
    setStatus(e.target.value);
  };

  const handleMobileChange = (e) => {
    setMobile(e.target.value);
  };

  const handleSupplierChange = (e) => {
    setSupplier(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (fName !== "") {
        if (email !== "" && email.match(emailRegx)) {
          if (password !== "") {
            if (usertype !== "") {
              if (
                mobile !== "" &&
                mobile.match(numberRegx) &&
                mobile.length === 10
              ) {
                if (
                  (usertype !== "admin" && supplier !== "") ||
                  (usertype === "admin" && supplier === "")
                ) {
                  let data = {
                    id,
                    firstName: fName,
                    lastName: lName,
                    fullName: fName + ' ' + lName,
                    email,
                    password,
                    userType: usertype,
                    status : true,
                    mobile,
                    vendorId: usertype !== "admin" ? Number(supplier) : null,
                  };
                  if (history.location.pathname === "/user") {
                    dispatch(createUser(data));
                  } else {
                    dispatch(updateUser(data));
                  }
                } else {
                  handleToast("Select Vendor");
                }
              } else {
                handleToast("Enter Mobile Number Correctly");
              }
            } else {
              handleToast("Select Usertype");
            }
          } else {
            handleToast("Enter Password");
          }
        } else {
          handleToast("Enter Email Correctly");
        }
    } else {
      handleToast("Enter First Name");
    }
  };

  const handleGoBack = () => {
    history.push("/user-list");
  };

  return (
    <div>
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col sm={12}>
            <CartTitle title={"User"} customBtnHide={true} />
          </Col>
        </Row>
        <div className="fullHeight backgroundCard CardWithLightBg rounded">
          <Row className=" px-2 px-lg-4 py-4">
            <Col
              lg={4}
              className={`${fName !== "" ? "valueActive" : ""}  mb-3`}
            >
              <InputText
                labelName={"First Name"}
                inputType={"text"}
                inputName={"fName"}
                tooltip={""}
                customValue={fName}
                customOnChange={(e) => handleFnameChange(e)}
              />
            </Col>{" "}
            <Col
              lg={4}
              className={`${lName !== "" ? "valueActive" : ""}  mb-3`}
            >
              <InputText
                labelName={"Last Name"}
                inputType={"text"}
                inputName={"lName"}
                tooltip={""}
                customValue={lName}
                customOnChange={(e) => handleLnameChange(e)}
              />
            </Col>
            <Col
              lg={4}
              className={`${email !== "" ? "valueActive" : ""}  mb-3`}
            >
              <InputText
                labelName={"Email"}
                inputType={"text"}
                inputName={"email"}
                tooltip={""}
                customValue={email}
                customOnChange={(e) => handleEmailChange(e)}
              />
            </Col>
            <Col
              lg={4}
              className={`${password !== "" ? "valueActive" : ""}  mb-3`}
            >
              <InputText
                labelName={"Password"}
                inputType={"text"}
                inputName={"password"}
                tooltip={""}
                customValue={password}
                customOnChange={(e) => handlePasswordChange(e)}
              />
            </Col>
            <Col
              lg={4}
              className={`${usertype !== "" ? "valueActive" : ""}  mb-3`}
            >
              <FormGroup>
                <select
                  className="form-control"
                  name="applicationFormType"
                  onChange={(e) => handleUserTypeChange(e)}
                  value={usertype}
                      style={{
                        fontWeight: 'bold',
                        fontSize: "1.2rem",
                      }}
                >
                  <option>Select Usertype</option>

                  <option value="admin">Admin</option>
                  <option value="user">User</option>
                </select>
                <span className="highlight" />
                <span className="bar" />
              </FormGroup>
            </Col>
{/*               <Col */}
{/*                 lg={4} */}
{/*                 className={`${status !== "" ? "valueActive" : ""}  mb-3`} */}
{/*               > */}
{/*                 <FormGroup> */}
{/*                   <select */}
{/*                     className="form-control" */}
{/*                     name="applicationFormType" */}
{/*                     onChange={(e) => handleStatusChange(e)} */}
{/*                     value={status} */}
{/*                       style={{ */}
{/*                         fontWeight: 'bold', */}
{/*                         fontSize: "1.2rem", */}
{/*                       }} */}
{/*                   > */}
{/*                     <option>Select Status</option> */}

{/*                     <option value={true}>True</option> */}
{/*                     <option value={false}>False</option> */}
{/*                   </select> */}
{/*                   <span className="highlight" /> */}
{/*                   <span className="bar" /> */}
{/*                 </FormGroup> */}
{/*               </Col> */}

            <Col
              lg={4}
              className={`${mobile !== "" ? "valueActive" : ""}  mb-3`}
            >
              <InputText
                labelName={"Mobile Number"}
                inputType={"text"}
                inputName={"mobile"}
                tooltip={""}
                customValue={mobile}
                customOnChange={(e) => handleMobileChange(e)}
              />
            </Col>
            {usertype !== "admin" && (
              <Col
                lg={4}
                className={`${supplier !== "" ? "valueActive" : ""} mb-3`}
              >
                <FormGroup>
                  <select
                    className="form-control"
                    name="applicationFormType"
                    onChange={(e) => handleSupplierChange(e)}
                      style={{
                        fontWeight: 'bold',
                        fontSize: "1.2rem",
                      }}
                  >
                    <option>
                      {history.location.pathname === "/user-update"
                        ? supplierName
                        : "Select Supplier"}
                    </option>
                    {allSuppliers?.map((item, index) => (
                      <option key={index} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                  <span className="highlight" />
                  <span className="bar" />
                </FormGroup>
              </Col>
            )}
            <Col sm="12">
              <div className="d-flex justify-content-end mb-sm-0 mb-lg-4">
                <CustomButton
                  customType={"submit"}
                  name={"Submit"}
                  customClass={
                    "customButtonsmall bgSecondary mr-2 btn-width-sm"
                  }
                />
                <CustomButton
                  customType={"button"}
                  name={"Cancel"}
                  customClass={"customButtonsmall bgPrimary btn-width-sm"}
                  customClick={handleGoBack}
                />
              </div>
            </Col>
          </Row>
        </div>{" "}
      </Form>
    </div>
  );
}
