/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { Row, Col } from "reactstrap";
import history from "../../history";

import CartTitle from "../../components/CartTitle";
import ListingTable from "../../components/listingTable";

import {
  getAllProducts,
  deleteProduct,
} from "../../redux/Hot_Products/actions";

function Product() {
  const columns = [
    {
      title: "Image",
      field: "image",
      render: (rowData) => (
        <img
          style={{ width: 40, height: 40, borderRadius: "50%" }}
          src={rowData.image}
        />
      ),
    },
    {
      title: "Name",
      field: "name",
    },
    {
      title: "Print Name",
      field: "productAlias",
    },
    {
      title: "HSN Code",
      field: "hsnCode",
    },
    {
      title: "Price",
      field: "price",
    },
  ];
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllProducts());
    sessionStorage.removeItem("update-product");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { allProduct } = useSelector((state) => state.Product, shallowEqual);

  const handleRoutetoCreateForm = () => {
    history.push("/create-product");
  };

  const handleDeleteRowFromTable = (oldData) =>
    new Promise((resolve) => {
      setTimeout(() => {
        resolve();
        dispatch(deleteProduct(oldData));
      }, 600);
    });

  const handleOnRowEditFromTable = (data) => {
    history.push(`/update-product`);
    sessionStorage.setItem("update-product", JSON.stringify(data));
  };

  return (
    <div>
      <Row>
        <Col sm={12}>
          <CartTitle
            title={"Products"}
            customBtnName={"Create Product"}
            customBtnClick={handleRoutetoCreateForm}
            customBtnHide={false}
          />
        </Col>
      </Row>
      <div className="CardWithLightBg backgroundCard fullHeight rounded">
        <Row>
          <Col sm="12">
            <ListingTable
              data={allProduct}
              columns={columns}
              onRowDelete={handleDeleteRowFromTable}
              onRowEdit={handleOnRowEditFromTable}
              tooltip={"Edit Product"}
              hidden2={true}
              hidden3={true}
            />
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default Product;
