import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Switch, Route } from "react-router";

import Aside from "../components/Aside";
import Header from "../components/Header";
import requireAuth from "../components/hoc";

import Supplier from "../pages/Supplier";
import Barcode from "../pages/Barcode-Printing";
import User from "../pages/User";
import Bill from "../pages/Bill-Invoice";
import CreateSupplier from "../pages/Create-Supplier";
import CreateUser from "../pages/Create-User";
import Product from "../pages/Products";
import CreateProduct from "../pages/Create-Product";
import CreateInvoice from "../pages/Create-Invoice";
import InvoicesDetails from "../pages/Invoice-details";

class Main extends Component {
  render() {
    return (
      <React.Fragment>
        <Header handleLogOut={this.handleLogOut} />
        <Aside />
        <div className="main">
          <Container fluid>
            <Row>
              <Col sm={12}>
                <Switch>
                  <Route path="/supplier-list" component={Supplier} />
                  <Route path="/user-list" component={User} />
                  <Route path="/barcode-print" component={Barcode} />
                  <Route path="/bill-list" component={Bill} />
                  <Route path="/supplier" component={CreateSupplier} />
                  <Route path="/supplier-update" component={CreateSupplier} />
                  <Route path="/user" component={CreateUser} />
                  <Route path="/user-update" component={CreateUser} />
                  <Route path="/product" component={Product} />
                  <Route path="/create-product" component={CreateProduct} />
                  <Route path="/update-product" component={CreateProduct} />
                  <Route path="/create-invoice" component={CreateInvoice} />
                  <Route path="/update-invoice" component={CreateInvoice} />
                  <Route path="/invoices" component={Bill} />
                  <Route path="/invoices-details" component={InvoicesDetails} />
                </Switch>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {};
}

function mapStateToProps(state) {
  return {};
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(requireAuth(Main))
);
