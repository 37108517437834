import history from "../../history";
import * as types from "./actionType";
import API from "../../utils/endpoints";

import { handleToast } from "../../utils/common";

export function creatingSupplier() {
  return {
    type: types.CREATE_SUPPLIER,
  };
}

export function creatingSupplierSuccess(payload) {
  return {
    type: types.CREATE_SUPPLIER_SUCCESS,
    payload,
  };
}

export function creatingSupplierFailed(payload) {
  return {
    type: types.CREATE_SUPPLIER_FAILED,
    payload,
  };
}

function fetchingSupplier() {
  return {
    type: types.FETCH_SUPPLIER,
  };
}

function fetchingSupplierSuccess(payload) {
  return {
    type: types.FETCH_SUPPLIER_SUCCESS,
    payload,
  };
}

function fetchingSupplierFailed(payload) {
  return {
    type: types.FETCH_SUPPLIER_FAILED,
    payload,
  };
}

function deletingSupplier() {
  return {
    type: types.DELETE_SUPPLIER,
  };
}

function deletingSupplierSuccess(payload) {
  return {
    type: types.DELETE_SUPPLIER_SUCCESS,
    payload,
  };
}

function deletingSupplierFailed(payload) {
  return {
    type: types.DELETE_SUPPLIER_FAILED,
    payload,
  };
}

function updatingSupplier() {
  return {
    type: types.UPDATE_SUPPLIER,
  };
}

function updatingSupplierSuccess(payload) {
  return {
    type: types.UPDATE_SUPPLIER_SUCCESS,
    payload,
  };
}

function updatingSupplierFailed(payload) {
  return {
    type: types.UPDATE_SUPPLIER_FAILED,
    payload,
  };
}

export const createSupplier = (payload) => async (dispatch, getState, api) => {
  try {
    dispatch(creatingSupplier());

    const response = await api.post(API.createSupplier, payload);
    if (response.data.code === 200) {
      dispatch(creatingSupplierSuccess(response.data.res));
      history.push("/supplier-list");
      handleToast(response.data.message);
    } else {
      dispatch(creatingSupplierFailed(response.data));
      handleToast(response.data.message);
    }
  } catch (error) {
    dispatch(creatingSupplierFailed());
//    console.log({ error });
    let message = error.response.data.message;
    handleToast(message);
  }
};

export const fetchSupplier = (payload) => async (dispatch, getState, api) => {
  dispatch(fetchingSupplier());
  try {
    const response = await api.get(API.fetchSupplier);
    if (response.data.code === 200) {
      dispatch(fetchingSupplierSuccess(response.data.res));
    } else {
      dispatch(fetchingSupplierFailed(response.data));
    }
  } catch (error) {
//    console.log({ error });
    let message = error.response.data.message;
    handleToast(message);
    dispatch(fetchingSupplierFailed(error));
  }
};

export const deleteSupplier = (payload) => async (dispatch, getState, api) => {
  dispatch(deletingSupplier());
  try {
    const response = await api.delete(`${API.deleteSupplier}/${payload.id}`, {
      data: payload,
    });
    if (response.data.code === 200) {
      dispatch(deletingSupplierSuccess(response.data.data));
      dispatch(fetchSupplier());
      handleToast(response.data.message);
    } else {
      dispatch(deletingSupplierFailed(response.data.data));
      handleToast(response.data.message);
    }
  } catch (error) {
//    console.log({ error });
    let message = error.response.data.message;
    handleToast(message);
    dispatch(deletingSupplierFailed(error));
  }
};

export const updateSupplier = (payload) => async (dispatch, getState, api) => {
  dispatch(updatingSupplier());
  try {
    const response = await api.put(
      `${API.updateSupplier}/${payload.id}`,
      payload
    );
    if (response.data.code === 200) {
      dispatch(updatingSupplierSuccess(response.data.res));
      history.push("/supplier-list");
      handleToast(response.data.message);
    } else {
      dispatch(updatingSupplierFailed());
      handleToast(response.data.message);
    }
  } catch (error) {
//    console.log({ error });
    let message = error.response.data.message;
    handleToast(message);
    dispatch(updatingSupplierFailed(error));
  }
};
