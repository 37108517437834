import React, { useEffect, useState } from "react";
import { Row, Col, Form } from "reactstrap";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import Cookies from "js-cookie";

import history from "../../history";

import InputText from "../../components/InputText";
import CartTitle from "../../components/CartTitle";
import CustomButton from "../../components/CustomButton";

import { printBarcode } from "../../redux/Bill-Invoice/actions";

export default function BarcodeP() {
  const dispatch = useDispatch();
  const [product, setProduct] = useState([]);

  useEffect(() => {
    const product = sessionStorage.getItem("barcode");
    const parsedProduct = JSON.parse(product);
    const vendorCode = Cookies.get("User");
    const parsedVenderCode = JSON.parse(vendorCode);
    handleSetUpdateData(parsedProduct, parsedVenderCode.vendorId);
  }, []);

  const { barcodePdf } = useSelector((state) => state.Invoice, shallowEqual);

  const handleSetUpdateData = (parsedProduct, vendorId) => {
    let productArray = parsedProduct.productList.map((item) => ({
      productId: item.productId,
      invoiceId: item.invoiceId,
      sellPrice: item.price,
      product: item.productName,
      vendorCode: Number(vendorId),
      quantity: item.quantity,
    }));

    setProduct(productArray);
  };

  const handleEditProduct = (e, index) => {
    let data = [...product];
    data[index][e.target.name] = e.target.value;
    setProduct(data);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(printBarcode(product));
  };

//   console.log({ barcodePdf }, { product });

  const handleGoBack = () => {
    history.push("/bill-list");
  };

  return (
    <div>
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col sm={12}>
            <CartTitle title={"Barcode"} customBtnHide={true} />
          </Col>
        </Row>
        <div className="fullHeight backgroundCard CardWithLightBg rounded">
          <Row className=" px-2 px-lg-4 py-4">
            {product.map((item, index) => (
              <>
                <Col
                  lg={6}
                  className={`${
                    item.product !== null ? "valueActive" : ""
                  }  mb-3`}
                >
                  <InputText
                    labelName={"Name"}
                    inputType={"text"}
                    inputName={"product"}
                    tooltip={""}
                    customValue={item.product}
                  />
                </Col>{" "}
                <Col
                  lg={6}
                  className={`${
                    item.quantity !== "" ? "valueActive" : ""
                  }  mb-3`}
                >
                  <InputText
                    labelName={"Quantity"}
                    inputType={"text"}
                    inputName={"quantity"}
                    tooltip={""}
                    customValue={item.quantity}
                    customOnChange={(e) => handleEditProduct(e, index)}
                  />
                </Col>
                {/* <Col
                  lg={4}
                  className={`${item.price !== "" ? "valueActive" : ""}  mb-3`}
                >
                  <InputText
                    labelName={"Selling Price"}
                    inputType={"text"}
                    inputName={"sellPrice"}
                    tooltip={""}
                    customValue={item.sellPrice}
                    customOnChange={(e) => handleEditProduct(e, index)}
                  />
                </Col> */}
              </>
            ))}

            <Col sm="12">
              <div className="d-flex justify-content-end mb-sm-0 mb-lg-4">
                <CustomButton
                  customType={"submit"}
                  name={"Print Barcode"}
                  customClass={
                    "customButtonsmall bgSecondary mr-2 btn-width-sm"
                  }
                />
                <CustomButton
                  customType={"button"}
                  name={"Cancel"}
                  customClass={"customButtonsmall bgPrimary btn-width-sm"}
                  customClick={handleGoBack}
                />
              </div>
            </Col>
          </Row>
        </div>{" "}
      </Form>
    </div>
  );
}
