import React, { useEffect } from "react";
import "./App.css";

import { Switch, Route, Router } from "react-router-dom";
import Cookies from "js-cookie";
import history from "./history";
import { Provider } from "react-redux";
import store from "./redux/store";
import { ToastContainer } from "react-toastify";

import Main from "./container";

import Login from "./pages/Login";
import OtpVerify from "./pages/Otp_Verify";

function App() {
  useEffect(() => {
    if (history.location.pathname === "/") {
      history.push("/login");
      Cookies.remove("SP_DASH");
      Cookies.remove("user");
    }
  }, []);
  return (
    <div className="App">
      <Provider store={store}>
        <div className="App">
          <ToastContainer autoClose={8000} />
          <Router history={history}>
            <Switch>
              <Route path="/login" name="login" component={Login} />
              <Route
                path="/otp-verify"
                name="otpVerify"
                component={OtpVerify}
              />

              <Route path="/" component={Main} />
            </Switch>
          </Router>
        </div>
      </Provider>
    </div>
  );
}
export default App;
