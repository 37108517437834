export const GET_ALL_PRODUCTS = "GET_ALL_PRODUCTS";
export const GET_ALL_PRODUCTS_SUCCESS = "GET_ALL_PRODUCTS_SUCCESS";
export const GET_ALL_PRODUCTS_FAILED = "GET_ALL_PRODUCTS_FAILED";

export const CREATE_PRODUCTS = "CREATE_PRODUCTS";
export const CREATE_PRODUCTS_SUCCESS = "CREATE_PRODUCTS_SUCCESS";
export const CREATE_PRODUCTS_FAILED = "CREATE_PRODUCTS_FAILED";

export const UPDATE_PRODUCTS = "UPDATE_PRODUCTS";
export const UPDATE_PRODUCTS_SUCCESS = "UPDATE_PRODUCTS_SUCCESS";
export const UPDATE_PRODUCTS_FAILED = "UPDATE_PRODUCTS_FAILED";

export const DELETE_PRODUCTS = "DELETE_PRODUCTS";
export const DELETE_PRODUCTS_SUCCESS = "DELETE_PRODUCTS_SUCCESS";
export const DELETE_PRODUCTS_FAILED = "DELETE_PRODUCTS_FAILED";

export const IMAGE_UPLOAD = "IMAGE_UPLOAD";
export const IMAGE_UPLOAD_SUCCESS = "IMAGE_UPLOAD_SUCCESS";
export const IMAGE_UPLOAD_FAILED = "IMAGE_UPLOAD_FAILED";

export const IMAGE_DOWNLOAD = "IMAGE_DOWNLOAD";
export const IMAGE_DOWNLOAD_SUCCESS = "IMAGE_DOWNLOAD_SUCCESS";
export const IMAGE_DOWNLOAD_FAILED = "IMAGE_DOWNLOAD_FAILED";