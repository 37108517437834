import React, { useState } from "react";

import {
  Container,
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  NavbarToggler,
} from "reactstrap";
import { Link } from "react-router-dom";
import Cookies from "js-cookie";

import { ic_menu } from "react-icons-kit/md/ic_menu";
import { Icon } from "react-icons-kit";
import UserImage from "../../assets/images/icons8-user-100.png";
import { logout } from "react-icons-kit/iconic/logout";
import history from "../../history";

import "../../assets/scss/components/header/index.scss";

function Header() {
  const [dropdownOpen1, setDropdownOpen1] = useState(false);

  const cookieeData = Cookies.get("User");
  let parsedData = JSON.parse(cookieeData);

  const addClass = () => {
    document.body.classList.toggle("aside-active");
  };

  const toggle = () => {
    setDropdownOpen1(!dropdownOpen1);
  };

  const handleLogout = () => {
    history.push("/login");
    Cookies.remove("SP_DASH");
    Cookies.remove("User");
    window.location.reload();
  };

  return (
    <header className="d-flex align-items-center headerBg">
      <Container
        fluid
        className="app-header d-flex align-items-center justify-content-between"
      >
        <div className="nav-header d-flex align-items-center">
          <NavbarToggler className="mr-2" onClick={addClass}>
            <Icon icon={ic_menu} size={30} style={{
                                                                                color: "#29388d",
                                                                              }} />
          </NavbarToggler>
          {parsedData?.userType !== "admin" && (
            <span
              style={{
                textAlign: "center",
                color: "#29388d",
                fontSize: "1.5rem",
                fontWeight: "bold",
                lineHeight: "1.5",
                textDecoration: "capital",
              }}
              className="text-capitalize"
            >
              Supplier : {parsedData.vendorName}
            </span>
          )}
        </div>

        <div className="header-quick-navbar d-flex">
          <ul className="m-0">
            <li>
              <Dropdown
                isOpen={dropdownOpen1}
                toggle={toggle}
                className="quick-menu"
              >
                <span style={{
                                      fontSize: "1.3rem",
                                      fontWeight: "bold",
                                      padding: "20px"
                                    }} className="text-capitalize text-white">
                  Hello {parsedData.firstName} {parsedData.lastName}
                </span>

                <DropdownToggle color="icon">
                  <img tag="false" src={UserImage} alt="" />
                </DropdownToggle>
                <DropdownMenu right className="dropdown-fix">
                  <div>
                    <Link
                      to="#"
                      onClick={handleLogout}
                      className="profile_link"
                    >
                      <Icon size={"22"} icon={logout} className="mr-3" />
                      <h5 className="text-center m-0">Logout</h5>
                    </Link>
                  </div>
                </DropdownMenu>
              </Dropdown>
            </li>
          </ul>
        </div>
      </Container>
    </header>
  );
}

export default Header;
