/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect } from "react";
import { Row, Col } from "reactstrap";
import { useDispatch, useSelector, shallowEqual } from "react-redux";

import CartTitle from "../../components/CartTitle";
import ListingTable from "../../components/listingTable";
import Loader from "../../components/Loader";
import history from "../../history";

import { fetchAllUser, deleteUser } from "../../redux/User/actions";

export default function User() {
  const columns = [
    {
      title: "Full Name",
      field: "fullName",
    },
    {
      title: "Email",
      field: "email",
    },
    {
      title: "Mobile",
      field: "mobile",
    },
    {
      title: "Usertype",
      field: "userType",
    },
    {
      title: "Vendor Name",
      field: "vendorName",
    },
  ];

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchAllUser());
    sessionStorage.removeItem("update-user");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { allUser, allUserStatus } = useSelector(
    (state) => state.User,
    shallowEqual
  );

  const handleDeleteRowFromTable = (oldData) =>
    new Promise((resolve) => {
      setTimeout(() => {
        resolve();
        dispatch(deleteUser(oldData));
      }, 600);
    });

  const handleRoutetoCreateForm = () => {
    history.push("/user");
  };

  const handleOnRowEditFromTable = (data) => {
    history.push(`/user-update`);
    sessionStorage.setItem("update-user", JSON.stringify(data));
  };

  return (
    <div>
      {allUserStatus && <Loader />}
      <Row>
        <Col sm={12}>
          <CartTitle
            title={"Users"}
            customBtnName={"Create User"}
            customBtnClick={handleRoutetoCreateForm}
            customBtnHide={false}
          />
        </Col>
      </Row>
      <Row>
        <Col sm="12">
          <div className="backgroundCard fullHeight CardWithLightBg rounded">
            <ListingTable
              data={allUser}
              columns={columns}
              onRowDelete={handleDeleteRowFromTable}
              onRowEdit={handleOnRowEditFromTable}
              tooltip={"Edit User"}
              hidden2={true}
              hidden3={true}
            />
          </div>
        </Col>
      </Row>
    </div>
  );
}
