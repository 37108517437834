import React from "react";

export default () => {
  return (
    <div>
      <div className="loader-wrapper d-flex flex-column justify-content-center align-items-center">
        <div className="loader-img">
          <h1>Loading....</h1>
        </div>
      </div>
    </div>
  );
};

// export default Loader;
// Loader.propTypes = {
//   loading: PropTypes.bool,
// };
