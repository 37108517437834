import React, { useEffect, useState } from "react";
import { Form, Row, Col } from "reactstrap";
import Icon from "react-icons-kit";
import ReactCardFlip from "react-card-flip";
import { ic_email } from "react-icons-kit/md/ic_email";
import { ic_perm_phone_msg } from "react-icons-kit/md/ic_perm_phone_msg";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import logo from "../../assets/images/RSMK_LOGO_1.svg";
import bannerGif from "../../assets/images/bannerGif.gif";
import CustomButton from "../../components/CustomButton";
import InputText from "../../components/InputText";
import "../../assets/scss/components/Login/index.scss";
import history from "../../history";

import { numberRegx } from "../../utils/regx";
import { loginWithMobileOtp } from "../../redux/Auth/actions";
import { handleToast } from "../../utils/common";

function OtpVerify() {
  const dispatch = useDispatch();
  const [otp, setOtp] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");

  const { loginUserId, mobileNumberForOtp } = useSelector(
    (state) => state.Auth,
    shallowEqual
  );

  useEffect(() => {
    if (!loginUserId) {
      history.push("/login");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setMobileNumber(mobileNumberForOtp);
  }, [mobileNumberForOtp]);

  const handleOtpChange = (e) => {
    setOtp(e.target.value);
  };

  const handleOtpVerification = (e) => {
    e.preventDefault();
    if (otp !== "" && otp.length === 6 && otp.match(numberRegx)) {
      dispatch(loginWithMobileOtp(otp));
    } else {
      handleToast("Enter Valid OTP");
    }
  };
  return (
    <div className="login-wrapper">
      <div className="whiteBg"></div>
      <div className="image-container">
        <img className="responsive-image" src={bannerGif} alt="Logo" />{" "}
      </div>
      <div className="main-bg d-flex flex-wrap align-items-center">
        <ReactCardFlip flipDirection="horizontal">
          <div>
            <div className="login-container">
              <div className="login-logo mx-auto">
                <img src={logo} alt="Logo" />
              </div>
              <div className="app-auth-container m-auto position-relative">
                <Form onSubmit={handleOtpVerification}>
                  <Row>
                    <Col sm={12} className="position-relative">
                      <div
                        style={{
                          textAlign: "center",
                          color: "#29388d",
                          fontSize: "1.3rem",
                          fontWeight: "bold",
                          lineHeight: "1.5",
                        }}
                      >
                        <label>
                          Enter OTP sent to Mobile No: {mobileNumber}
                        </label>
                      </div>
                    </Col>
                    <Col sm={12} className="position-relative">
                      <InputText
                        inputType={"text"}
                        inputName={"otp"}
                        value={otp}
                        inputPlaceholder={"Enter OTP "}
                        tooltip={"Use Alphanumeric Only"}
                        customOnChange={handleOtpChange}
                      />
                      <Icon
                        size={22}
                        className="inputIcon LeftIcon"
                        icon={ic_perm_phone_msg}
                      />
                    </Col>
                  </Row>
                  <div className="bottom-sec">
                    <div className="d-flex align-items-center justify-content-center position-relative flex-column">
                      <CustomButton
                        customType={"submit"}
                        name={"Verify OTP"}
                        customClass={
                          "border-0 bgSecondary customButtonsmall rounded-pill mb-4 btn-width-sm"
                        }
                      />
                    </div>
                  </div>
                </Form>{" "}
              </div>
            </div>
          </div>
          <div>
            <div className="login-container">
              <div className="login-logo mx-auto">
                <img src="" alt="Logo" />
              </div>
              <div className="app-auth-container m-auto position-relative">
                <Form>
                  <Row>
                    <Col lg={12}>
                      <InputText
                        inputType={"text"}
                        inputName={"email"}
                        inputPlaceholder={"Enter Username"}
                      />
                      <Icon
                        size={22}
                        className="inputIcon LeftIcon"
                        icon={ic_email}
                      />
                    </Col>
                  </Row>
                  <div className="bottom-sec">
                    <div className="d-flex align-items-center flex-column justify-content-center position-relative">
                      <CustomButton
                        customType={"submit"}
                        name={"Reset Password"}
                        customClass={
                          "border-0 bgSecondary customButtonsmall rounded-pill mb-4 btn-width-sm"
                        }
                      />
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </ReactCardFlip>
      </div>
    </div>
  );
}

export default OtpVerify;
