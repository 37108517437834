import React, { useState, useEffect } from "react";
import { Row, Col, Form, FormGroup } from "reactstrap";
import { useDispatch } from "react-redux";

import InputText from "../../components/InputText";
import CartTitle from "../../components/CartTitle";
import CustomButton from "../../components/CustomButton";

import { createSupplier, updateSupplier } from "../../redux/Supplier/actions";
import { handleToast } from "../../utils/common";
import history from "../../history";

import { numberRegx } from "../../utils/regx";
import BrokerList from "../../utils/BrokerList.json";

export default function CreateUser() {
  const dispatch = useDispatch();
  const [id, setId] = useState("");
  const [name, setName] = useState("");
  const [code, setCode] = useState("");
  const [mobile, setMobile] = useState("");
  const [address, setAddress] = useState("");
  const [gstin, setGstin] = useState("");
  const [broker, setBroker] = useState("");
  const [salePricePercentage, setSalePricePercentage] = useState("");
  const [salePriceFix, setSalePriceFix] = useState("");

  useEffect(() => {
    if (history.location.pathname === "/supplier-update") {
      const user = sessionStorage.getItem("update-supplier");
      const parsedUser = JSON.parse(user);
      handleSetUpdateData(parsedUser);
    }
  }, []);

  const handleSetUpdateData = (parsedUser) => {
    setId(parsedUser.id);
    setName(parsedUser.name);
    setCode(parsedUser.code);
    setMobile(parsedUser.mobile);
    setAddress(parsedUser.address);
    setGstin(parsedUser.gstin);
    setBroker(parsedUser.broker);
    setSalePricePercentage(parsedUser.salePricePercentage);
    setSalePriceFix(parsedUser.salePriceFix);
  };

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  // const handleCodeChange = (e) => {
  //   setCode(e.target.value);
  // };

  const handleMobileChange = (e) => {
    setMobile(e.target.value);
  };

  const handleAddressChange = (e) => {
    setAddress(e.target.value);
  };

  const handleGstinChange = (e) => {
    setGstin(e.target.value);
  };

  const handleBrokerChange = (e) => {
    setBroker(e.target.value);
  };

  const handleSalePricePercentageChange = (e) => {
    const value = e.target.value;
    setSalePricePercentage(value);
    if (value) {
      setSalePriceFix("");
    }
  };

  const handleSalePriceFixChange = (e) => {
    const value = e.target.value;
    setSalePriceFix(value);
    if (value) {
      setSalePricePercentage("");
    }
  };
  const isSalePricePercentageDisabled = salePriceFix !== "";
  const isSalePriceFixDisabled = salePricePercentage !== "";

  const handleSubmit = (e) => {
    e.preventDefault();
    if (name !== "") {
      if (address !== "") {
        if (gstin !== "" && gstin.length === 15) {
          if (
            mobile !== "" &&
            mobile.match(numberRegx) &&
            mobile.length === 10
          ) {
            if (broker !== "") {
              if (
                (salePricePercentage !== "" ) ||
                (salePriceFix !== "" && String(salePriceFix).match(numberRegx))
              ) {
                let data = {
                  id,
                  name,
                  code,
                  mobile,
                  address,
                  gstin,
                  broker,
                  salePricePercentage,
                  salePriceFix,
                };
                if (history.location.pathname === "/supplier-update") {
                  dispatch(updateSupplier(data));
                } else {
                  dispatch(createSupplier(data));
                }
              } else {
                handleToast(
                  "Enter either Sale Price Percentage or Sale Price Fix Correctly"
                );
              }
            } else {
              handleToast("Enter Broker");
            }
          } else {
            handleToast("Enter Mobile Number Correctly");
          }
        } else {
          handleToast("Enter GST in correct format.");
        }
      } else {
        handleToast("Enter Address");
      }
    } else {
      handleToast("Enter Name");
    }
  };

  const handleGoBack = () => {
    history.push("/supplier-list");
  };

  return (
    <div>
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col sm={12}>
            <CartTitle title={"Supplier"} customBtnHide={true} />
          </Col>
        </Row>
        <div className="fullHeight backgroundCard CardWithLightBg rounded">
          <Row className=" px-2 px-lg-4 py-4">
            <Col lg={4} className={`${name !== "" ? "valueActive" : ""}  mb-3`}>
              <InputText
                labelName={"Name"}
                inputType={"text"}
                inputName={"name"}
                tooltip={""}
                customOnChange={(e) => handleNameChange(e)}
                customValue={name}
              />
            </Col>
            {/* <Col lg={4} className={`${code !== "" ? "valueActive" : ""}  mb-3`}>
              <InputText
                labelName={"Code"}
                inputType={"text"}
                inputName={"code"}
                tooltip={""}
                customOnChange={(e) => handleCodeChange(e)}
                customValue={code}
              />
            </Col> */}
            <Col
              lg={4}
              className={`${address !== "" ? "valueActive" : ""}  mb-3`}
            >
              <InputText
                labelName={"Address"}
                inputType={"text"}
                inputName={"address"}
                tooltip={""}
                customOnChange={(e) => handleAddressChange(e)}
                customValue={address}
              />
            </Col>
            <Col
              lg={4}
              className={`${gstin !== "" ? "valueActive" : ""}  mb-3`}
            >
              <InputText
                labelName={"GSTIN"}
                inputType={"text"}
                inputName={"gstin"}
                tooltip={""}
                customOnChange={(e) => handleGstinChange(e)}
                customValue={gstin}
              />
            </Col>
            <Col
              lg={4}
              className={`${mobile !== "" ? "valueActive" : ""}  mb-3`}
            >
              <InputText
                labelName={"Mobile Number"}
                inputType={"text"}
                inputName={"mobile"}
                tooltip={""}
                customOnChange={(e) => handleMobileChange(e)}
                customValue={mobile}
              />
            </Col>

            <Col
              lg={4}
              className={`${broker !== "" ? "valueActive" : ""}  mb-3`}
            >
              <FormGroup>
                <select
                  className="form-control"
                  name="applicationFormType"
                  onChange={(e) => handleBrokerChange(e)}
                  value={broker}
                      style={{
                        fontWeight: 'bold',
                        fontSize: "1.2rem",
                      }}
                >
                  <option>Select Broker</option>
                  {BrokerList.map((item) => (
                    <option value={item}>{item}</option>
                  ))}
                </select>
                <span className="highlight" />
                <span className="bar" />
              </FormGroup>
            </Col>

            <Col
              lg={4}
              className={`${
                salePricePercentage !== "" ? "valueActive" : ""
              }  mb-3`}
            >
              <InputText
                labelName={"Sale Price %"}
                inputType={"text"}
                inputName={"salePricePercentage"}
                tooltip={""}
                customOnChange={(e) => handleSalePricePercentageChange(e)}
                customValue={salePricePercentage}
                disabled={isSalePricePercentageDisabled}
              />
            </Col>
            <Col
              lg={4}
              className={`${salePriceFix !== "" ? "valueActive" : ""}  mb-3`}
            >
              <InputText
                labelName={"Sale Price Fixed"}
                inputType={"text"}
                inputName={"salePriceFix"}
                tooltip={""}
                customOnChange={(e) => handleSalePriceFixChange(e)}
                customValue={salePriceFix}
                disabled={isSalePriceFixDisabled}
              />
            </Col>

            <Col sm="12">
              <div className="d-flex justify-content-end mb-sm-0 mb-lg-4">
                <CustomButton
                  customType={"submit"}
                  name={"Submit"}
                  customClass={
                    "customButtonsmall bgSecondary mr-2 btn-width-sm"
                  }
                />
                <CustomButton
                  customType={"button"}
                  name={"Cancel"}
                  customClass={"customButtonsmall bgPrimary btn-width-sm"}
                  customClick={handleGoBack}
                />
              </div>
            </Col>
          </Row>
        </div>{" "}
      </Form>
    </div>
  );
}
