import Cookies from "js-cookie";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../index.css";

export const setAuthenticationCookie = async ({ accessToken }) => {
  try {
    console.log("===Access====", accessToken);
    await Cookies.set("SP_DASH", `Bearer ${accessToken}`, { expires: 7 });
  } catch (error) {
    console.error("Error storing cookies", error.message);
  }
};

export const getAuthenticationCookie = async () => {
  const accessToken = await Cookies.get("SP_DASH");

  if (!accessToken) return false;

  console.log({ accessToken });
  return { accessToken };
};

export const handleToast = (message) => {
  toast.error(message, {
    position: "bottom-center",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    text: "center",
  });
};
