/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect } from "react";
import { Row, Col } from "reactstrap";
import { useDispatch, useSelector, shallowEqual } from "react-redux";

import CartTitle from "../../components/CartTitle";
import ListingTable from "../../components/listingTable";
import Loader from "../../components/Loader";
import history from "../../history";

import { fetchSupplier, deleteSupplier } from "../../redux/Supplier/actions";

export default function Supplier() {
  const columns = [
    {
      title: "Name",
      field: "name",
    },
    {
      title: "Code",
      field: "code",
    },
    {
      title: "GSTIN",
      field: "gstin",
    },
    {
      title: "Mobile",
      field: "mobile",
    },
    {
      title: "Broker Name",
      field: "broker",
    },
  ];

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchSupplier());
    sessionStorage.removeItem("update-supplier");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { allSuppliers, allSuppliersStatus } = useSelector(
    (state) => state.Supplier,
    shallowEqual
  );

  const handleDeleteRowFromTable = (oldData) =>
    new Promise((resolve) => {
      setTimeout(() => {
        resolve();
        dispatch(deleteSupplier(oldData));
//         console.log({ oldData });
      }, 600);
    });

  const handleRoutetoCreateForm = () => {
    history.push("/supplier");
  };

  const handleOnRowEditFromTable = (data) => {
    history.push(`/supplier-update`);
    sessionStorage.setItem("update-supplier", JSON.stringify(data));
  };

  return (
    <>
      {allSuppliersStatus && <Loader />}
      <div>
        <Row>
          <Col sm={12}>
            <CartTitle
              title={"Supplier"}
              customBtnName={"Create Supplier"}
              customBtnClick={handleRoutetoCreateForm}
              customBtnHide={false}
            />
          </Col>
        </Row>
        <Row>
          <Col sm="12">
            <div className="backgroundCard fullHeight CardWithLightBg rounded">
              <ListingTable
                data={allSuppliers}
                columns={columns}
                onRowDelete={handleDeleteRowFromTable}
                onRowEdit={handleOnRowEditFromTable}
                tooltip={"Edit Supplier"}
                hidden2={true}
                hidden3={true}
              />
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
}
