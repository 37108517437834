export default {
  baseUrl: process.env.REACT_APP_SERVER_URL,

  //Urls

  //Auth
  loginUser: "/auth/login",

  getUserData: "/api/users",

  sendOtpForLogin: "/api/otp/generate-otp",

  loginWithMobileOtp: "/api/otp/validate",

  //Suppliers

  fetchSupplier: "/api/vendors",

  createSupplier: "/api/vendors",

  deleteSupplier: "/api/vendors",

  updateSupplier: "/api/vendors",

  //User

  fetchAllUser: "/api/users",

  createUser: "/api/users",

  deleteUser: "/api/users",

  updateUser: "/api/users",

  //Products

  createProduct: "/api/products",

  getAllProducts: "/api/products",

  updateProduct: "/api/products",

  deleteProduct: "/api/products",

  uploadImage: "/upload",

  //Invoices

  getAllInvoices: "/invoices/vendor",

  createInvoice: "/invoice",

  updateInvoice: "/invoice",

  deleteInvoice: "/invoice",

  getAllInvoicesForAdmin: "/invoices",

  printBarcode: "/generate-qr",

  downloadPdfInvoice: "/download/invoice",
};
