import * as types from "./actionType";
import API from "../../utils/endpoints";
import Cookies from "js-cookie";
import history from "../../history";

import { handleToast } from "../../utils/common";

export function fetchingInvoices() {
  return {
    type: types.GET_INVOICES,
  };
}

export function fetchingInvoicesSuccess(payload) {
  return {
    type: types.GET_INVOICES_SUCCESS,
    payload,
  };
}

export function fetchingInvoicesFailed(payload) {
  return {
    type: types.GET_INVOICES_FAILED,
    payload,
  };
}

export function creatingInvoices() {
  return {
    type: types.CREATE_INVOICES,
  };
}

export function creatingInvoicesSuccess(payload) {
  return {
    type: types.CREATE_INVOICES_SUCCESS,
    payload,
  };
}

export function creatingInvoicesFailed(payload) {
  return {
    type: types.CREATE_INVOICES_FAILED,
    payload,
  };
}

export function updatingInvoices() {
  return {
    type: types.UPDATE_INVOICES,
  };
}

export function updatingInvoicesSuccess(payload) {
  return {
    type: types.UPDATE_INVOICES_SUCCESS,
    payload,
  };
}

export function updatingInvoicesFailed(payload) {
  return {
    type: types.UPDATE_INVOICES_FAILED,
    payload,
  };
}

export function deletingInvoices() {
  return {
    type: types.DELETE_INVOICES,
  };
}

export function deletingInvoicesSuccess(payload) {
  return {
    type: types.DELETE_INVOICES_SUCCESS,
    payload,
  };
}

export function deletingInvoicesFailed(payload) {
  return {
    type: types.DELETE_INVOICES_FAILED,
    payload,
  };
}

export function printingBarcode() {
  return {
    type: types.PRINT_BARCODE,
  };
}

export function printingBarcodeSuccess(payload) {
  return {
    type: types.PRINT_BARCODE_SUCCESS,
    payload,
  };
}

export function printingBarcodeFailed(payload) {
  return {
    type: types.PRINT_BARCODE_FAILED,
    payload,
  };
}

export function downloadingPdfInvoice() {
  return {
    type: types.DOWNLOAD_PDF_INVOICE,
  };
}

export function downloadingPdfInvoiceSuccess(payload) {
  return {
    type: types.DOWNLOAD_PDF_INVOICE_SUCCESS,
    payload,
  };
}

export function downloadingPdfInvoiceFailed(payload) {
  return {
    type: types.DOWNLOAD_PDF_INVOICE_FAILED,
    payload,
  };
}

//****** get all Invoices   ********//

export const getAllInvoices = () => async (dispatch, getState, api) => {
  dispatch(fetchingInvoices());
  try {
    const cookieeData = Cookies.get("User");
    let parsedData = JSON.parse(cookieeData);
    let response;

    if (parsedData.userType === "admin") {
      response = await api.get(API.getAllInvoicesForAdmin);
    } else {
      response = await api.get(`${API.getAllInvoices}/${parsedData.vendorId}`);
    }

    if (response.data.code === 200) {
      dispatch(fetchingInvoicesSuccess(response.data.res));
    } else {
      dispatch(fetchingInvoicesFailed([]));
    }
  } catch (error) {
//    console.log({ error });
    let message = error.response.data.message;
    handleToast(message);
    dispatch(fetchingInvoicesFailed([]));
  }
};

//****** create Invoices  ********//

export const createInvoice = (payload) => async (dispatch, getState, api) => {
  dispatch(creatingInvoices());
  try {
    const response = await api.post(API.createInvoice, payload);
    if (response.data.code === 200) {
      handleToast(response.data.message);
      dispatch(creatingInvoicesSuccess(response.data.res));
      dispatch(getAllInvoices());
      history.push("/bill-list");
    } else {
      handleToast(response.data.message);
      dispatch(creatingInvoicesFailed([]));
    }
  } catch (error) {
//    console.log({ error });
    let message = error.response.data.message;
    handleToast(message);
    dispatch(creatingInvoicesFailed([]));
  }
};

//****** update Invoices  ********//

export const updateInvoice = (payload) => async (dispatch, getState, api) => {
  dispatch(updatingInvoices());
  try {
    const response = await api.put(`${API.updateInvoice}/${payload.invoiceId}`, payload);
    if (response.data.code === 200) {
      handleToast(response.data.message);
      dispatch(updatingInvoicesSuccess(response.data.res));
      dispatch(getAllInvoices());
      history.push("/bill-list");
    } else {
      handleToast(response.data.message);
      dispatch(updatingInvoicesFailed([]));
    }
  } catch (error) {
//    console.log({ error });
    let message = error.response.data.message;
    handleToast(message);
    dispatch(updatingInvoicesFailed([]));
  }
};

//****** delete Invoice  ********//

export const deleteInvoice = (payload) => async (dispatch, getState, api) => {
  dispatch(deletingInvoices());
  try {
    const response = await api.delete(`${API.deleteInvoice}/${payload.invoiceId}`);
    if (response.data.code === 200) {
      handleToast(response.data.message);
      dispatch(deletingInvoicesSuccess(response.data.res));
      dispatch(getAllInvoices());
    } else {
      handleToast(response.data.message);
      dispatch(deletingInvoicesFailed([]));
    }
  } catch (error) {
//    console.log({ error });
    let message = error.response.data.message;
    handleToast(message);
    dispatch(deletingInvoicesFailed([]));
  }
};

//****** create Invoices  ********//

export const printBarcode = (payload) => async (dispatch, getState, api) => {
  dispatch(printingBarcode());
  try {
    const response = await api.post(API.printBarcode, payload);
    if (response.status === 200) {
      handleToast(response.data.message);
      dispatch(printingBarcodeSuccess(response.data.res));
      const pdfUrl = response.data.res;
      const link = document.createElement("a");
      link.href = pdfUrl;
      document.body.appendChild(link);
      link.click();
    } else {
      handleToast(response.data.message);
      dispatch(printingBarcodeFailed([]));
    }
  } catch (error) {
//    console.log({ error });
    let message = error.response.data.message;
    handleToast(message);
    dispatch(printingBarcodeFailed([]));
  }
};

export const downloadPdfInvoice =
  (payload) => async (dispatch, getState, api) => {
    dispatch(downloadingPdfInvoice());
    try {
      const response = await api.post(`${API.downloadPdfInvoice}/${payload}`);
      if (response.data.code === 200) {
        handleToast(response.data.message);
        dispatch(downloadingPdfInvoiceSuccess(response.data.res));
        const pdfUrl = response.data.res;
        const link = document.createElement("a");
        link.href = pdfUrl;
        document.body.appendChild(link);
        link.click();
      } else {
        handleToast(response.data.message);
        dispatch(downloadingPdfInvoiceFailed([]));
      }
    } catch (error) {
//      console.log({ error });
      let message = error.response.data.message;
      handleToast(message);
      dispatch(downloadingPdfInvoiceFailed([]));
    }
  };
