import React from "react";

import "../../assets/scss/components/Aside/index.scss";
import Navigation from "../Navigation";

const Sidebar = () => {
  return (
    <React.Fragment>
      <div className="aside">
        <Navigation />
      </div>
    </React.Fragment>
  );
};

export default Sidebar;
