import * as types from "./actionType";

const initialState = {
  createProductStatus: false,
  updateProductStatus: false,
  deleteProductStatus: false,
  allProductStatus: false,
  allProduct: [],
  teacherFromSubject: [],
  uploadImageForProductStatus: false,
  uploadImageForProduct: null,
  downloadImageForProduct: false,
};

function productReducer(state = initialState, action) {
  switch (action.type) {
    case types.GET_ALL_PRODUCTS:
      return {
        ...state,
        allProductStatus: true,
        allProduct: [],
      };
    case types.GET_ALL_PRODUCTS_SUCCESS:
      return {
        ...state,
        allProductStatus: false,
        allProduct: action.payload,
      };

    case types.GET_ALL_PRODUCTS_FAILED:
      return {
        ...state,
        allProductStatus: false,
        allProduct: [],
      };

    case types.CREATE_PRODUCTS:
      return {
        ...state,
        createProductStatus: true,
      };
    case types.CREATE_PRODUCTS_SUCCESS:
      return {
        ...state,
        createProductStatus: false,
      };

    case types.CREATE_PRODUCTS_FAILED:
      return {
        ...state,
        createProductStatus: false,
      };

    case types.UPDATE_PRODUCTS:
      return {
        ...state,
        updateProductStatus: true,
      };
    case types.UPDATE_PRODUCTS_SUCCESS:
      return {
        ...state,
        updateProductStatus: false,
      };

    case types.UPDATE_PRODUCTS_FAILED:
      return {
        ...state,
        updateProductStatus: false,
      };

    case types.DELETE_PRODUCTS:
      return {
        ...state,
        deleteProductStatus: true,
      };
    case types.DELETE_PRODUCTS_SUCCESS:
      return {
        ...state,
        deleteProductStatus: false,
      };

    case types.DELETE_PRODUCTS_FAILED:
      return {
        ...state,
        deleteProductStatus: false,
      };

    case types.IMAGE_UPLOAD:
      return {
        ...state,
        uploadImageForProductStatus: true,
        uploadImageForProduct: null,
      };
    case types.IMAGE_UPLOAD_SUCCESS:
      return {
        ...state,
        uploadImageForProductStatus: false,
        uploadImageForProduct: action.payload,
      };

    case types.IMAGE_UPLOAD_FAILED:
      return {
        ...state,
        uploadImageForProductStatus: false,
        uploadImageForProduct: null,
      };

    case types.IMAGE_DOWNLOAD:
      return {
        ...state,
        downloadImageForProduct: true,
      };
    case types.IMAGE_DOWNLOAD_SUCCESS:
      return {
        ...state,
        downloadImageForProduct: false,
      };

    case types.IMAGE_DOWNLOAD_FAILED:
      return {
        ...state,
        downloadImageForProduct: false,
      };
    default:
      return state;
  }
}

export default productReducer;
