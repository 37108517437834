import { applyMiddleware, compose, createStore } from "redux";
import Cookies from "js-cookie";
import thunk from "redux-thunk";
import axios from "axios";
import reducers from "./rootReducer";
import API from "../utils/endpoints";
import { handleToast } from "../utils/common";

const axiosInstance = axios.create({
  baseURL: API.baseUrl,
});

const enhancers = compose(
  applyMiddleware(thunk.withExtraArgument(axiosInstance))
);
const store = createStore(reducers, enhancers);

axiosInstance.interceptors.request.use(async function (config) {
  const accessToken = await Cookies.get("SP_DASH");
  config.headers.Authorization = accessToken ? accessToken : "";
  return config;
});

axiosInstance.interceptors.response.use(async function (response) {
  if (
    response.status === 200 &&
    response.data &&
    [401, 402].includes(response.data.code) //401
  ) {
    Cookies.remove("SP_DASH");
    Cookies.remove("User");
    window.location.reload();
    handleToast("Session Expired Please Login Again");
  } else {
    return response;
  }
});

export default store;
