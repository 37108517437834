//****** LOGIN  ********//
export const LOGIN_USER = "edupan/Auth/LOGIN_USER";
export const LOGIN_USER_SUCCESS = "edupan/Auth/LOGIN_USER_SUCCESS";
export const LOGIN_USER_FAILED = "edupan/Auth/LOGIN_USER_FAILED";

export const SEND_OTP = "SEND_OTP";
export const SEND_OTP_SUCCESS = "SEND_OTP_SUCCESS";
export const SEND_OTP_FAILED = "SEND_OTP_FAILED";

export const LOGIN_WITH_MOBILE = "LOGIN_WITH_MOBILE";
export const LOGIN_WITH_MOBILE_SUCCESS = "LOGIN_WITH_MOBILE_SUCCESS";
export const LOGIN_WITH_MOBILE_FAILED = "LOGIN_WITH_MOBILE_FAILED";

export const GET_USER_DATA = "GET_USER_DATA";
export const GET_USER_DATA_SUCCESS = "GET_USER_DATA_SUCCESS";
export const GET_USER_DATA_FAILED = "GET_USER_DATA_FAILED";